/* eslint-disable import/prefer-default-export */
import { AxiosAAA, AxiosHumanar, AxiosORGS, OrgId } from "./orgRepository";

export const orgDesignApi = {
  $_getAllOrgUsers(payload) {
    const url = `/user/${OrgId}?${payload}`;
    return AxiosAAA.get(url);
  },
  $_getAllOrgUsersWithOptions(payload) {
    const url = `/org/${OrgId}/users?${payload}`;
    return AxiosORGS.get(url);
  },
  $_getAOneOrgUserWithOptions(id, payload = "") {
    const url = `/users/${id}?${payload}`;
    return AxiosAAA.get(url);
  },
  $_getOrgStats() {
    const url = `dashboard/employees-stats/${OrgId}`;
    return AxiosHumanar.get(url);
  },
  $_setSupervisoryFunction(payload) {
    const url = `/org/functions/reporting`;
    return AxiosORGS.patch(url, payload);
  },
  $_getOffCyclePay() {
    const url = `/org/${OrgId}/employees/off-cycle`;
    return  AxiosORGS.get(url);
  },
};
