/* eslint-disable import/prefer-default-export */
import { AxiosDocuments } from "./orgRepository";

export const documentsApi = {
  $_getAllFiles(orgId,params) {
    const url = `/documents/org/${orgId}/files/?${params}`;
    return AxiosDocuments.get(url);
  },
  $_getAllDeletedFolders(orgId, params) {
    const url = `/documents/org/${orgId}/folders/deleted/?${params}`;
    return AxiosDocuments.get(url);
  },
  $_getAllDeletedFiles(orgId, params) {
    const url = `/documents/org/${orgId}/files/deleted/?${params}`;
    return AxiosDocuments.get(url);
  },
  $_getAllFolders(orgId,params) {
    const url = `/documents/org/${orgId}/folders/?${params}`;
    return AxiosDocuments.get(url);
  },
  $_getAllEmployeesFolders(orgId) {
    const url = `/documents/org/${orgId}/folders?type=employees`;
    return AxiosDocuments.get(url);
  },
  $_getAllEmployeesFiles(orgId) {
    const url = `/documents/org/${orgId}/files/?type=employees`
    return AxiosDocuments.get(url);
  },
  $_getAllDeletedEmployeesFolders(orgId) {
    const url = `/documents/org/${orgId}/folders/deleted?type=employees`;
    return AxiosDocuments.get(url);
  },
  $_getAllDeletedEmployeesFiles(orgId) {
    const url = `/documents/org/${orgId}/files/deleted?type=employees`
    return AxiosDocuments.get(url);
  },
  $_getAllEmployees(orgId, params) {
    const url = `/documents/org/${orgId}/employees${params}`
    return AxiosDocuments.get(url);
  },
  $_getEmployeesSummary(orgId) {
    const url = `/documents/org/${orgId}/employees/summary`
    return AxiosDocuments.get(url);
  },
  $_getAnEmployees(orgId, userId) {
    const url = `/documents/org/${orgId}/employees/view/${userId}`
    return AxiosDocuments.get(url);
  },
  $_getAFolder(folderId) {
    const url = `/documents/folders/${folderId}`
    return AxiosDocuments.get(url);
  },
  $_getAllGroups(orgId) {
    const url = `/documents/org/${orgId}/groups/`;
    return AxiosDocuments.get(url);
  },
  $_deleteFolderForOrg(folderId, payload) {
    const url = `/documents/folders/${folderId}`;
    return AxiosDocuments.delete(url,  payload);
  },
  $_deleteFileForOrg(fileId, payload) {
    const url = `/documents/files/${fileId}`;
    return AxiosDocuments.delete(url,  payload);
  },
  $_getFolderAuditInfo(folderId) {
    const url = `/documents/folders/${folderId}/audit-information`;
    return AxiosDocuments.get(url);
  },
  $_getFileAuditInfo(fileId) {
    const url = `/documents/files/${fileId}/audit-information`;
    return AxiosDocuments.get(url);
  },
  $_editOrgFolder({folderId,payload}) {
    const url = `/documents/folders/${folderId}`;
    return AxiosDocuments.patch(url,payload);
  },
  $_editOrgFile({fileId,payload}) {
    const url = `/documents/files/${fileId}`;
    return AxiosDocuments.patch(url,payload);
  },
  $_moveOrgFileToFolder({fileId, payload}) {
    const url = `/documents/files/${fileId}/move-to-folder`;
    return AxiosDocuments.post(url,payload);
  },
  $_moveOrgFolderToFolder(payload) {
    const url = `/documents/folders/move`;
    return AxiosDocuments.post(url, payload);
  },
  $_managePermissions(id, payload, type) {
    const url = `documents/${type}/${id}/permissions`;
    return AxiosDocuments.post(url,payload);
  },
  $_uploadAFile(payload){
    const url = `/documents/files`
    return AxiosDocuments.post(url,payload);
  },
  $_createFolderForOrg(payload) {
    const url = `/documents/folders?type=organization`;
    return AxiosDocuments.post(url, payload);
  },
  $_createFolderForEmployees(payload) {
    const url = `/documents/folders?type=employees`;
    return AxiosDocuments.post(url, payload);
  },
  $_createAGroup(payload) {
    const url = `/documents/groups`;
    return AxiosDocuments.post(url, payload);
  },
  $_updateAGroup(groupId, payload) {
    const url = `/documents/groups/${groupId}`
    return AxiosDocuments.patch(url, payload);
  },
  $_deleteAGroup(groupId) {
    const url = `/documents/groups/${groupId}`;
    return AxiosDocuments.delete(url);
  },
  $_restoreAFolder(folderId) {
    const url = `/documents/folders/${folderId}/restore`;
    return AxiosDocuments.patch(url);
  },
  $_restoreAFile(fileId) {
    const url = `/documents/files/${fileId}/restore`;
    return AxiosDocuments.patch(url);
  },
  $_downloadFolderAsZip(folderId) {
    const url = `/documents/folders/${folderId}/download`;
    return AxiosDocuments.get(url, {responseType: 'blob'});
  },
  $_manageStorageAllocation(orgId, payload) {
    const url = `/documents/org/${orgId}/storage`;
    return AxiosDocuments.patch(url, payload);
  },
};
