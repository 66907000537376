import benefitsRoutes from "./benefitsRoutes";
import documentRoutes from "./documentRoutes";
import approvalRoutes from "./approvalRoutes";
import employeeRoutes from "./employeeRoutes";
import performanceRoutes from "./performanceRoutes";
import timeAttendanceRoutes from "./timeAttendanceRoutes";
import managerSelfServiceRoutes from "./managerSelfServiceRoutes";

export default [
    ...benefitsRoutes,
    ...documentRoutes,
    ...approvalRoutes,
    ...employeeRoutes,
    ...performanceRoutes,
    ...timeAttendanceRoutes,
    ...managerSelfServiceRoutes
];
