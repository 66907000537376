import privilegeManager from "@/utilities/privilege";

const isTrial = store => {
  const { orgApps } = store.state.linchpin;
  const humanarApp = orgApps.find(app => app.appSlug === "humanar");
  return humanarApp.subscription.status === "trial";
};
export default async function isSubscribed({ next, to, store }) {
  const modules = {
    organisation: "orgAndPeople",
    payroll: "payroll",
    employee: "employeeManagement",
    "leave-management": "timeOff",
    overtime: "overtime",
    "advance-and-loan": "advanceAndLoan",
    performance: "performanceManagement",
    compensation: "compensationPlanning",
  };
  const route = {
    organisation: "orgAndPeople",
    locationAndTax: "location",
    payroll: "payroll",
    employee: "employeeManagement",
    "leave-management": "timeOff",
    overtime: "overtime",
    "advance-and-loan": "advanceAndLoan",
    performance: "performanceManagement",
    compensation: "compensationPlanning",
    settings: 'settings',
    approval: 'approval',
    timeAttendance: 'timeAttendance',
    analytics: 'analytics'
  }
  if (store.getters.hasSubscriptionExpired()) {
    return next({
      name: "AdminDashboard"
    });
  }

  if (!isTrial(store)) {
    const subscriptionData = localStorage.getItem("subscription");
    const parsedSubscriptionData = JSON.parse(subscriptionData);

    if (!subscriptionData) {
      await store.dispatch("getSubscription");
    }

    const subscriptionStore = store.state.subscription;

    if (modules[to.path.split("/")[1]]) {
      const apps = subscriptionStore ? subscriptionStore.susbcribeModules : parsedSubscriptionData.susbcribeModules;
      const url = modules[to.path.split("/")[1]];

      const hasApp = apps.find(app => app.name === url);

      if (!hasApp) {
        return next({ name: "ErrorNoAccess" });
      }
    }
  }
  const { isRoot, roles } = store.state.linchpin.user;
      if (!isRoot) {
        if (roles.humanar && route[to.path.split("/")[1]]) {
          await store.dispatch("getPrivileges");
          const moduleName = route[to.path.split("/")[1]];
          store.commit("SET_MODULE_NAME", moduleName);
          // Check if the user has access to the module
          const hasAccess = privilegeManager(
            store.state.privileges,
            moduleName
          );
          if (!hasAccess) {
            // return the user to a restricted page
            return next({ name: "ErrorRestricted" });
          }
        }
      }
  return next();
}
