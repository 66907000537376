/* eslint-disable import/prefer-default-export */
import { AxiosHumanar, OrgId, $AuthUser, AxiosORGS } from "./orgRepository";

export const leaveApi = {
  $_applyForLeave(payload) {
    const url = "/leave/requests";
    return AxiosHumanar.post(url, payload);
  },
  $_createLeaveRequest(payload) {
    const url = "/leave/requests";
    return AxiosHumanar.post(url, payload);
  },
  $_updateLeaveRequest({ payload, requestId }) {
    const url = `/leave/requests/${requestId}`;
    return AxiosHumanar.patch(url, payload);
  },
  $_approveLeaveRequestByEmployee(payload) {
    const url = "/leave/employees-requests-approval";
    return AxiosHumanar.post(url, payload);
  },
  $_approveLeaveBackRequestByEmployee(payload) {
    const url = "/leave/employees-leave-back-requests-approval";
    return AxiosHumanar.post(url, payload);
  },
  $_approveLeaveRequestByEmployeeDetail(payload) {
    const url = "/leave/requests-approval";
    return AxiosHumanar.post(url, payload);
  },
  $_approveLeaveBackRequestByEmployeeDetail(payload) {
    const url = "/leave/leave-back-requests-approval";
    return AxiosHumanar.post(url, payload);
  },
  $_addNewCategory(payload) {
    const url = "/leave/categories";
    return AxiosHumanar.post(url, payload);
  },
  $_applyForLeaveBack(payload) {
    const url = "/leave/leave-back-requests";
    return AxiosHumanar.post(url, payload);
  },
  $_updateLeaveBack({requestId, payload }) {
    const url = `/leave/leave-back-requests/${requestId}`;
    return AxiosHumanar.patch(url, payload);
  },
  $_getEmployeeCategory({ userId }) {
    const url = `/leave/categories/${userId}`;
    return AxiosHumanar.get(url);
  },
  $_postTentativeApproval(payload){
    const url = `leave/org/${OrgId}/employee-leave-plan/approve`;
    return AxiosHumanar.post(url, payload);
  },
  $_getAdminAnnualPlans(params) {
    const url = `/leave/org/${OrgId}/plans`;
    return AxiosHumanar.get(url, { params });
  },
  $_getManagerAnnualPlans(params) {
    const url = `/leave-plans/manager/${$AuthUser.id}`;
    return AxiosHumanar.get(url, { params });
  },
  $_getAdminAnnualPlanDetails({ leaveYear, params }) {
    const url = `/leave/org/${OrgId}/plans/${leaveYear}`;
    return AxiosHumanar.get(url, { params });
  },
  $_getAdminEmployeeAnnualLeave({ planId, params }) {
    const url = `/leave/org/${OrgId}/employee-leave-plan/${planId}`;
    return AxiosHumanar.get(url, { params });
  },
  $_getAdminLeaveCalendar(params) {
    const url = `/leave/org/${OrgId}/annual-plan/calendar`;
    return AxiosHumanar.get(url, { params });
  },
  $_getAdminLeave(params) {
    const url = `/leave/all/organization/leave-request/${OrgId}`;
    return AxiosHumanar.get(url, { params });
  },
  $_getAdminLeaveStats() {
    const url = `/leave/organization/leave-request-statistics/${OrgId}`;
    return AxiosHumanar.get(url);
  },
  $_getAdminLeaveCategories({ year, month }) {
    const url = `/leave/types/${OrgId}/${month}/${year}`;
    return AxiosHumanar.get(url);
  },
  $_getAdminEmployeeRequest({ year, month, status, categoryId }) {
    const url = `/leave/employees-requests/${OrgId}/${month}/${year}?approvalStatus=${status}&categoryId=${categoryId}`;
    return AxiosHumanar.get(url);
  },
  $_getAdminEmployeeDetail({ year, month, status, categoryId, employeeId }) {
    const url = `/leave/requests/employees/${employeeId}?approvalStatus=${status}&yearRequested=${year}&monthRequested=${month}&categoryId=${categoryId}`;
    return AxiosHumanar.get(url);
  },

  $_getLeaveAnalytics({ managerId }) {
    const url = `/leave/manager/${managerId}/dashboard`;
    return AxiosHumanar.get(url);
  },
  $_getLeaveBackAnalytics({ managerId }) {
    const url = `/leave/leave-back-requests/${managerId}/dashboard`;
    return AxiosHumanar.get(url);
  },
  $_getAdminLeaveBackRequest(params){
    const url = `/leave-back-requests/${OrgId}`;
    return AxiosHumanar.get(url, { params });
  },
  $_getDelegationAnalytics({ managerId }) {
    const url = `/leave/delegations/${managerId}/dashboard`;
    return AxiosHumanar.get(url);
  },
  $_getEmployeeOnLeave({ managerId }) {
    const url = `/leave/delegations/${managerId}/employees-on-leave`;
    return AxiosHumanar.get(url);
  },
  $_getAnEmployeeOnLeave({ userId }) {
    const url = `/leave/delegations/employees-on-leave/${userId}`;
    return AxiosHumanar.get(url);
  },
  $_getEmployeeAtWork({ managerId, payload }) {
    if (payload) {
      const url = `/leave/delegations/${managerId}/employees-at-work/?${payload}`;
      return AxiosHumanar.get(url);
    }
    const url = `/leave/delegations/${managerId}/employees-at-work`;
    return AxiosHumanar.get(url);
  },
  $_getOrgLeaveRequest() {
    const url = `/leave/requests/org/${OrgId}`;
    return AxiosHumanar.get(url);
  },
  $_getOrgLeaveDaysLeft(payload) {
    const url = `/leave/number-of-leave-days/${payload.userId}/${payload.startDate}/${payload.endDate}`;
    return AxiosHumanar.get(url);
  },
  $_getManagerLeaveRequests({ managerId, status }) {
    const url = `leave/manager/${managerId}/employees-requests?approvalStatus=${status}`;
    return AxiosHumanar.get(url);
  },
  $_getManagerLeaveBackRequests({ managerId, status }) {
    const url = `leave/leave-back-requests/${managerId}/employees-requests?approvalStatus=${status}`;
    return AxiosHumanar.get(url);
  },
  $_getLeaveSettings() {
    const url = `/leave/settings/org/${OrgId}`;
    return AxiosHumanar.get(url);
  },
  $_getOneLeaveSettings() {
    const url = `/leave/settings/org/${OrgId}/category/3`;
    return AxiosHumanar.get(url);
  },
  $_getLeaveCategory() {
    const url = `/leave/categories/org/${OrgId}`;
    return AxiosORGS.get(url);
  },
  $_getLeaveCategoryLevelsSettings() {
    const url = `/leave/settings/org/${OrgId}/category/5`;
    return AxiosHumanar.get(url);
  },
  $_getOneLeaveCategoryLevelsSettings({ id }) {
    const url = `/leave/settings/org/${OrgId}/categories/3/levels/${id}`;
    return AxiosHumanar.get(url);
  },
  $_getLeaveCategoryForEmployee({ id }) {
    const url = `/leave/categories/employees/${OrgId}/levels/${id}`;
    return AxiosHumanar.get(url);
  },
  $_getCategoryGeneralLeaveSetting() {
    const url = `/leave/settings/org/${OrgId}/categories/:categoryId/general`;
    return AxiosHumanar.get(url);
  },
  // TODO: use this
  $_getEmployeeLeaveRequest({ employeeId, params }) {
    const url = `/leave/requests/employees/${employeeId}`;
    return AxiosHumanar.get(url, { params });
  },
  $_getEmployeeLeavePlan({ userId, params }){
    const url = `/leave/employee/${userId}/plans`;
    return AxiosHumanar.get(url, { params });
  },
  $_createAnnualLeavePlan(payload){
    const url = `/leave/year-plan`;
    return AxiosHumanar.post(url, payload);
  },
  $_updateAnnualLeavePlan({ payload, planId }){
    const url = `leave/year-plan/${planId}`;
    return AxiosHumanar.patch(url, payload);
  },
  $_getLeavePlanInformation({ userId, year }){
    const url = `/leave/employee/${userId}/details/${year}`;
    return AxiosHumanar.get(url);
  },
  $_getEmployeeLeaveBackRequest({ id, params }) {
    const url = `/leave/leave-back-requests/employees/${id}`;
    return AxiosHumanar.get(url, { params });
  },
  $_leaveRequestApproval(payload) {
    const url = "/leave/requests/approval";
    return AxiosHumanar.patch(url, payload);
  },
  $_leaveDelegation(payload) {
    const url = "/leave/delegations/change-leave-delegation";
    return AxiosHumanar.patch(url, payload);
  },
  $_yearEndLeaveBalance({ userId, params }) {
    const url = `/year-leave-balances/${userId}`;
    return AxiosHumanar.get(url, { params });
  },
  $_orgYearEndLeaveBalance(params) {
    const url = `/leave/org/year-leave-balances/${OrgId}`;
    return AxiosHumanar.get(url, { params });
  },
  $_ManagerEmployeesYearEndLeaveBalance(params) {
    const url = `/leave/manager/${$AuthUser.id}/year-end-leave-balances${params}`;
    return AxiosHumanar.get(url, { params });
  },
  $_orgYearEndLeaveBalanceDetails({ year, params }) {
    const url = `/leave/org/year-leave-balances/${OrgId}/details/${year}`;
    return AxiosHumanar.get(url, { params });
  },
  $_ManagerYearEndLeaveBalanceDetails({ year, params }) {
    const url = `/leave/manager/${$AuthUser.id}/year-end-leave-balances/${year}`;
    return AxiosHumanar.get(url, { params });
  },
  $_getOneOffLeaveBalances(params, year) {
    const url = `leave/org/year-leave-balances/${OrgId}/details/${year}`;
    return AxiosHumanar.get(url, { params });
  },
  $_getLeaveCategoryBalances(category, params) {
    const url = `/leave/settings/org/${OrgId}/one-off-balances/${category}`;
    return AxiosHumanar.get(url, {params});
  },
  $_submitOneOffLeaveBalances(payload) {
    const url = `/leave/settings/org/${OrgId}/one-off-balances`;
    return AxiosHumanar.post(url, payload);
  },
  $_getOnleaveCategories() {
    const url = `/leave/settings/org/${OrgId}`;
    return AxiosHumanar.get(url);
  },
  $_updateLevelSettings(payload) {
    const url = "/leave/settings";
    return AxiosHumanar.patch(url, payload);
  },
  $_inheritLevelSettings(payload) {
    const url = "/leave/settings/inherit";
    return AxiosHumanar.patch(url, payload);
  },
  $_updateCategoryGeneralLeaveSetting(payload) {
    const url = "/leave/settings/general";
    return AxiosHumanar.patch(url, payload);
  },
  $_deleteLevelCategory() {
    const url = "/leave/categories/:categoryId";
    return AxiosHumanar.delete(url);
  },
  $_getEmployeeAnnualLeavePlan({ userId, planId }){
    const url = `/leave/employee/${userId}/plans/${planId}`
    return AxiosHumanar.get(url)
  },
  $_cancelDaysBackRequest(requestId){
    const url = `/leave/cancel-leave-back-requests/${requestId}`;
    return AxiosHumanar.post(url)
  },
  $_cancelLeaveRequest(requestId){
    const url = `/leave/cancel-requests/${requestId}`;
    return AxiosHumanar.post(url)
  },
  $_employeeLeaveInsight(userId) {
    const url = `/leave/requests/insight/${userId}`;
    return AxiosHumanar.get(url)
  },
  $_yearEndBalanceUserSummary(userId, year) {
    const url = `/leave/categories/${userId}/balance/${year}`;
    return AxiosHumanar.get(url)
  }
};
