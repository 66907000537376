/* eslint-disable import/prefer-default-export */
import axios from "axios";
import { orgDesignApi } from "./orgDesignApiFactory";
import { employeeApi } from "./employeeApiFactory";
import { leaveApi } from "./leaveApiFactory";
import { salaryAdvanceApi } from "./salaryAdvanceApi";
import { advanceloanApi } from "./advance-loanAPi";
import { PerformanceApi } from "./performanceApi";
import { ApprovalApi } from "./approvalApi";
import { overtimeApi } from "./overTimeApiFactory";
import { paymentApi } from "./paymentApiFactory";
import { compensationApi } from "./compensationApi";
import { analyticsApi } from "./analyticsApi";
import { timeAttendanceApi } from "./timeAttendanceApi";
import { managerSelfServiceAPI } from "./managerSelfServiceAPI";
import { documentsApi } from "./documentsAPIFactory";

import {
  AxiosAAA,
  AxiosHumanar,
  AxiosNotification,
  AxiosORGS,
  OrgId,
  $AuthUser,
  $simpleAuthHeader,
} from "./orgRepository";

const validateParam = (param) => {
  if (!param || !param.length) return "";
  return param;
};
const apiFactory = {
  ...orgDesignApi,
  ...employeeApi,
  ...leaveApi,
  ...overtimeApi,
  ...salaryAdvanceApi,
  ...advanceloanApi,
  ...PerformanceApi,
  ...ApprovalApi,
  ...paymentApi,
  ...compensationApi,
  ...analyticsApi,
  ...timeAttendanceApi,
  ...managerSelfServiceAPI,
  ...documentsApi,
  $_getLinchpin() {
    const url = "/auth/refresh_linchpin";
    return AxiosORGS.get(url);
  },
  $_getPrivileges() {
    const url = `/roles/${$AuthUser.roles.humanar.roleId}/users`;
    return AxiosORGS.get(url);
  },
  $_getConfirmationSettings() {
    const url = `/action/settings/${OrgId}`;
    return AxiosHumanar.get(url);
  },
  $_saveConfirmationSetting(payload) {
    const url = `/action/settings/${OrgId}`;
    return AxiosHumanar.post(url, payload);
  },
  $_getAppSettings() {
    const url = `/org/${OrgId}/appsettings/`;
    return  AxiosORGS.get(url);
  },
  $_getPayrollDashboard(query) {
    const url = `/payroll/${OrgId}/overview/?${query}`;
    return AxiosHumanar.get(url);
  },
  $_getPayrollItems() {
    const url = `/payroll/payrun/statistics/${OrgId}`;
    return AxiosHumanar.get(url);
  },
  $_initialOffCyclePay(payload) {
    const url = `/payroll/off-cycle/initiate`;
    return AxiosORGS.post(url, payload);
  },
  $_getOffCycleMonths({orgId, offCycleId}) {
    const url = `/payroll/off-cycle-months/${orgId}/${offCycleId}`;
    return AxiosORGS.get(url);
  },
  $_getOffCyclePayRegister({orgId, offCycleId, month, year}, payload) {
    const url = `/payroll/off-cycle/${orgId}/${offCycleId}/${month}/${year}/?type=off-cycle&${payload}`;
    return AxiosORGS.get(url);
  },
  $_getOrgAppearance() {
    const url = `/org/appearance/${OrgId}`;
    return  AxiosORGS.get(url);
  },
  $_saveOrgAppearance(payload) {
    const url = `/org/appearance/${OrgId}`;
    return  AxiosORGS.post(url, payload);
  },
  $_updateCriterias(payload) {
    const url = `/action/settings/${OrgId}`;
    return AxiosHumanar.patch(url, payload);
  },
  $_updateAppSettings(payload) {
    const url = "/org/appsettings";
    return  AxiosORGS.patch(url, payload);
  },
  $_getOrgProfile() {
    const url = `/org/${OrgId}`;
    return  AxiosORGS.get(url);
  },
  $_getTaxAuthority() {
    const url = `/org/${OrgId}/tax/tins?tinRequired=true`;
    return  AxiosORGS.get(url);
  },
  $_getNotifications(query) {
    const url = `/notification/${$AuthUser.id}/humanar${query}`;
    return AxiosNotification.get(url);
  },
  $_markNotificationsAsRead(payload) {
    const url = `/notification/mark-as-read`;
    return AxiosNotification.patch(url, payload);
  },
  $_markNotificationsAsUnRead(payload) {
    const url = `/notification/mark-as-unread`;
    return AxiosNotification.patch(url, payload);
  },
  $_deleteNotification(id) {
    const url = `/notification/delete/${id}`;
    return AxiosNotification.delete(url);
  },
  $_saveTaxRemittance(payload) {
    const url = "save/tax-remittance-option";
    return AxiosHumanar.post(url, payload);
  },
  $_checkPassword(payload) {
    const url = "/org/security/passwords/confirm";
    return AxiosORGS.post(url, payload);
  },
  $_updateOrgProfile(payload) {
    const url = "/org/settings";
    return AxiosORGS.patch(url, payload);
  },
  $_getOrgSettings() {
    const url = `/org/${OrgId}/settings`;
    return AxiosORGS.get(url);
  },
  $_getDefaultOfficeLocation() {
    const url = `/org/${OrgId}/outlets/default-office-location`;
    return AxiosORGS.get(url);
  },
  $_getHumanarLocations(param) {
    const url = `/org/${OrgId}/outlets/hr?attributes=''${validateParam(param)}`;
    return AxiosORGS.get(url);
  },
  $_getOneHumanarLocations(payload) {
    const url = `/org/outlets/id/${payload}`;
    return AxiosORGS.get(url);
  },
  $_addOutlet(payload) {
    const url = `/org/${OrgId}/outlet`;
    return AxiosORGS.post(url, payload);
  },
  $_updateOutlet(payload) {
    const url = "/org/outlet/update";
    return AxiosORGS.patch(url, payload);
  },
  $_deleteOutlet(payload) {
    const url = "/org/outlet/delete";
    return AxiosORGS.delete(url, { headers: $simpleAuthHeader, data: payload });
  },
  $_getCordinates() {
    const url = `/org/${OrgId}/outlets/latlong`;
    return AxiosORGS.get(url);
  },
  $_getPaySchedules() {
    const url = `pay-schedules/${OrgId}/list`;
    return AxiosHumanar.get(url);
  },

  $_getPaySchedulesSetup() {
    const url = `pay-schedules/${OrgId}/setup`;
    return AxiosHumanar.get(url);
  },
  $_getOnePaySchedule(id) {
    const url = `pay-schedules/${id}`;
    return AxiosHumanar.get(url);
  },
  $_deletePaySchedules(id) {
    const url = `pay-schedules/${id}`;
    return AxiosHumanar.delete(url);
  },
  $_createPaySchedule(payload) {
    const url = "/pay-schedules";
    return AxiosHumanar.post(url, payload);
  },
  $_createPayrollStartDate(payload) {
    const url = "/payroll/start-date";
    return AxiosHumanar.post(url, payload);
  },
  $_getPayrollStartDate() {
    const url = `/payroll/start-date/${OrgId}`;
    return AxiosHumanar.get(url);
  },
  $_updatePaySchedules({ id, payload }) {
    const url = `/pay-schedules/${id}`;
    return AxiosHumanar.patch(url, payload);
  },
  $_disburseFunds(payload) {
    const url = "payroll/disburse";
    return AxiosHumanar.post(url, payload);
  },
  $_getAdminAnalytics() {
    const url = `/org/${OrgId}/analytics`;
    return AxiosORGS.get(url);
  },

  // Payroll
  $_getEmployeePayroll(payload) {
    const url = `payroll/${OrgId}/employees/${payload}`;
    return AxiosHumanar.get(url);
  },
  $_getOverviewYear() {
    const url = `payroll/${OrgId}/viewyears`;
    return AxiosHumanar.get(url);
  },

  $_getPayrunData(payload) {
    const url = "payroll/payrun/initiate";
    return AxiosHumanar.post(url, payload);
  },

  $_submitTerminalPay(payload) {
    const url = `terminal/${OrgId}/submit`;
    return AxiosHumanar.post(url, payload);
  },
  $_removeTerminalPay(terminalId) {
    const url = `terminal/${terminalId}`;
    return AxiosHumanar.delete(url);
  },

  $_getEmployeePayrun({ month, year }, payload) {
    const url = `/payroll/payrun/${OrgId}/${month}/${year}/?type=regular&${payload}`;
    return AxiosHumanar.get(url);
  },
  $_getEmployeeHourlyPayrun({ month, year, params }) {
    const url = `/payroll/payrun/${OrgId}/${month}/${year}/`;
    return AxiosHumanar.get(url, { params });
  },
  $_getAdditionsDeductions({ month, year, userId, type }) {
    const url = `/payroll/additions-and-deductions/${userId}/${month}/${year}/?type=${type}`;
    return AxiosHumanar.get(url);
  },
  $_addAdditionsDeductions(payload) {
    const url = `/payroll/additions-and-deductions/bulk`;
    return AxiosHumanar.post(url, payload);
  },
  $_editAdditionsDeductions(payload) {
    const url = `/payroll/additions-and-deductions/bulk`;
    return AxiosHumanar.patch(url, payload);
  },
  $_deleteAdditionsDeductions(id) {
    const url = `/payroll/additions-and-deductions/${id}/`;
    return AxiosHumanar.delete(url);
  },
  $_getPayrun(payload, params, payrollType) {
    const url = `/org-payroll/payrun/${OrgId}?type=${payrollType}&payrollStatus=${payload}${params}`;
    return AxiosHumanar.get(url);
  },
  $_getOnePayrun(payload, params) {
    const url = `/payroll/payrun/${payload}${params}`;
    return AxiosHumanar.get(url);
  },
  $_deletOnePayrun(payload) {
    const url = `/payroll/payrun/${payload}`;
    return AxiosHumanar.delete(url);
  },
  $_editOnePayrun(payload) {
    const url = "/payroll/payrun";
    return AxiosHumanar.patch(url, payload);
  },
  $_deletePayrun(payrunId) {
    const url = `/payroll/payrun/reset/${payrunId}`;
    return AxiosHumanar.delete(url);
  },
  $_getPayrunReviewed(payload) {
    const url = `/payroll/${OrgId}/payrun/${payload.year}/${payload.month}?status=reviewed`;
    return AxiosHumanar.get(url, payload);
  },
  $_getBackPay() {
    const url = `/backpay/unprocessed/${OrgId}`;
    return AxiosHumanar.get(url);
  },
  $_getBackPayEmployees(month, year) {
    const url = `/backpay/employees/${OrgId}/${month}/${year}`;
    return AxiosHumanar.get(url);
  },
  $_initiateBackPay(payload) {
    const url = `/backpay/initiate`;
    return AxiosHumanar.post(url, payload);
  },
  $_getTerminalPayEmployees() {
    const url = `/terminal/${OrgId}/employees`;
    return AxiosHumanar.get(url);
  },
  $_getListTerminatedPayrun() {
    const url = `/terminal/${OrgId}/unprocessed`;
    return AxiosHumanar.get(url);
  },
  $_getAllPayItems(userId) {
    const url = `/terminal/outstandings/${OrgId}/${userId}`;
    return AxiosHumanar.get(url);
  },
  $_processOutstandings(payload) {
    const url = `/terminal/outstandings/${OrgId}`;
    return AxiosHumanar.post(url, payload);
  },
  $_processTerminatedEmployees(payload) {
    const url = `/terminal/${OrgId}/process`;
    return AxiosHumanar.post(url, payload);
  },
  $_getProcessedEmployees({ processedId, params }) {
    const url = `/terminal/${OrgId}/employees/${processedId}`;
    return AxiosHumanar.get(url, { params });
  },
  $_getThirteenthEmployees(query) {
    const url = `/thirteenth/${OrgId}/employees?${query}`;
    return AxiosHumanar.get(url);
  },
  $_submitThirteenthMonth(payload) {
    const url = `/thirteenth/${OrgId}/submit`;
    return AxiosHumanar.post(url, payload);
  },
  $_getReviewPayrunData(payload) {
    const url = "payroll/payrun/review";
    return AxiosHumanar.post(url, payload);
  },
  $_getPayCycle(payruNo) {
    const url = `payroll/paycycle/${payruNo}`;
    return AxiosHumanar.get(url);
  },
  $_savePayrun(payload) {
    const url = "payroll/payrun";
    return AxiosHumanar.patch(url, payload);
  },
  $_getComputedHourlyPayData({ month, year, queryParams }) {
    const url = `/payroll/payrun/${OrgId}/${month}/${year}/compute-hourly-data`;
    return AxiosHumanar.get(url, { params: queryParams });
  },
  $_patchComputedHourlyPayData({ month, year, payload }) {
    const url = `/payroll/payrun/${OrgId}/${month}/${year}/compute-hourly-data`;
    return AxiosHumanar.patch(url, payload);
  },
  $_makePayrunPayment(payload) {
    const url = "/payroll/payrun/nuban-pay";
    return AxiosHumanar.post(url, payload);
  },
  $_payOffline(payload) {
    const url = "/payroll/payrun/pay-offline";
    return AxiosHumanar.post(url, payload);
  },
  $_exportFile(payrunId) {
    const url = `payroll/${payrunId}/download `;
    return AxiosORGS.get(url, { responseType: "arraybuffer" });
  },
  $_payPensionOffline(payload) {
    const url = "/payroll/pension/pay/pay-offline";
    return AxiosHumanar.post(url, payload);
  },
  $_payPayeOffline(payload) {
    const url = "payroll/paye/pay/offline";
    return AxiosHumanar.post(url, payload);
  },
  $_remitPension(payload) {
    const url = "/payroll/pension/pay/online";
    return AxiosHumanar.post(url, payload);
  },
  $_remitPaye(payload) {
    const url = "/payroll/paye/pay/online";
    return AxiosHumanar.post(url, payload);
  },
  $_payNhfOffline(payload) {
    const url = "/payroll/nhf/pay-offline";
    return AxiosHumanar.post(url, payload);
  },
  $_cancelApproved(payload) {
    const url = "/payroll/payrun/cancel";
    return AxiosHumanar.post(url, payload);
  },

  $_getPreviousPayruns() {
    const url = `payroll/${OrgId}/payrun/previous`;
    return AxiosHumanar.get(url);
  },
  $_getPreviousPaycycleDates() {
    const url = `payroll/${OrgId}/paycycle/previous`;
    return AxiosHumanar.get(url);
  },
  $_getEmployees() {
    const url = `${OrgId}/employees`;
    return AxiosHumanar.get(url);
  },
  $_getEmployeesDashboard(payload) {
    const url = `ess-dashboard/${OrgId}/${payload.employeeUserId}/${payload.year}`;
    return AxiosHumanar.get(url);
  },
  $_getOneEmployee(payload, query) {
    const url = `${OrgId}/employees/${payload}?${query}`;
    return AxiosHumanar.get(url);
  },
  $_updateEmployee(payload, query) {
    const url = `${OrgId}/employees/${payload.id}?${query}`;
    return AxiosHumanar.patch(url, payload);
  },
  $_getEmployeeAnnouncements() {
    const url = "http://35.177.193.208:58001/engagement/announcement";
    return axios.get(url);
  },
  $_getEmployeeAnnouncement(payload) {
    const url = `http://35.177.193.208:58001/engagement/announcement/${OrgId}/${payload}`;
    return axios.get(url);
  },
  $_getEmployeeAnnouncementCategories() {
    const url = "http://35.177.193.208:58001/engagement/msg-cat";
    return axios.get(url);
  },
  $_getEmployeeAnnouncementRecipients() {
    const url =
      "http://35.177.193.208:58001/engagement/announcement-recipients";
    return axios.get(url);
  },
  $_addEmployeeAnnouncement(payload) {
    const url = "http://35.177.193.208:58001/engagement/announcement";
    return axios.post(url, payload);
  },
  $_addEmployeeAnnouncementCategory(payload) {
    const url = "http://35.177.193.208:58001/engagement/msg-cat";
    return axios.post(url, payload);
  },
  $_getLeaveCategoryEmployees(payload) {
    const url = `leave/categories/employees/${payload.id}/levels/${payload.levelId}`;
    return AxiosHumanar.get(url);
  },
  $_getSalaryAdvanceSettings() {
    const url = `salary-advance/settings/org/${OrgId}`;
    return AxiosHumanar.get(url);
  },
  $_SalaryAdvanceSettings(payload) {
    const url = "salary-advance/settings";
    return AxiosHumanar.patch(url, payload);
  },
  $_getAdvanceSettings() {
    const url = `/org/${OrgId}/advance-and-loans/pay-items`;
    return AxiosORGS.get(url);
  },
  $_AdvanceSettings(payload) {
    const url = "/paysettings/global/advance-and-loans";
    return AxiosHumanar.post(url, payload);
  },
  $_applyForAdavanceAndLoan(payload) {
    const url = `/advances-and-loans/requests`;
    return AxiosHumanar.post(url, payload);
  },
  $_getGeneralAdvanceSettings() {
    const url = `/paysettings/global/${OrgId}/advance-and-loans`;
    return AxiosHumanar.get(url);
  },
  $_getSettingsForAdvance(payItemId) {
    const url = `/employees/${payItemId.employeeId}/levels/${payItemId.levelId}/pay-items/${payItemId.id}`;
    return AxiosHumanar.get(url);
  },
  $_updateGeneralSettings(payItemId, payload) {
    const url = `/advance-and-loans/settings/${payItemId}`;
    return AxiosHumanar.patch(url, payload);
  },
  // pay setting
  $_getPayPreferences() {
    const url = `/org/${OrgId}/paysettings/pay-preferences/get`;
    return AxiosHumanar.get(url);
  },
  $_savePayPreferences(payload) {
    const url = `/org/${OrgId}/paysettings/pay-preferences/`;
    return AxiosHumanar.post(url, payload);
  },
  $_getPayItemCategories() {
    const url = `/org/${OrgId}/paysettings/earnings/categories`;
    return AxiosORGS.get(url);
  },
  $_getEarnings() {
    const url = "/paysettings/earnings/payitems";
    return AxiosHumanar.get(url);
  },
  $_getDeductions() {
    const url = "/paysettings/deductions/payitems";
    return AxiosHumanar.get(url);
  },
  $_getDeductionCategories() {
    const url = `/org/${OrgId}/paysettings/deductions/categories`;
    return AxiosORGS.get(url);
  },
  $_getContributionCategories() {
    const url = `/org/${OrgId}/paysettings/contributions/categories`;
    return AxiosORGS.get(url);
  },
  $_updatePayItemSettings(payload) {
    const url = `/org/paysettings/${payload.id}`;
    return AxiosORGS.patch(url, payload);
  },
  $_deletePayItemSettings(payload) {
    const url = `/org/paysettings/${payload}`;
    return AxiosORGS.delete(url);
  },
  $_updatePayItemLevelSettings(payload) {
    const url = "/org/paysettings/levels/multiple";
    return AxiosORGS.patch(url, payload);
  },
  $_updateOrgGross(payload) {
    const url = `/org/${OrgId}/paysettings/earnings/gross`;
    return AxiosORGS.patch(url, payload);
  },
  $_getOnePayItemSettings(payItemId) {
    const url = `/org/${OrgId}/paysettings/${payItemId}/details`;
    return AxiosORGS.get(url);
  },
  $_getEarningPayItems() {
    const url = `/org/${OrgId}/paysettings/earnings`;
    return AxiosORGS.get(url);
  },
  $_getDeductionPayItems() {
    const url = `/org/${OrgId}/paysettings/deductions`;
    return AxiosORGS.get(url);
  },
  $_getContributionPayItems() {
    const url = `/org/${OrgId}/paysettings/contributions`;
    return AxiosORGS.get(url);
  },
  $_getOrgGrossTotal() {
    const url = `/org/${OrgId}/paysettings/earnings/gross/total`;
    return AxiosORGS.get(url);
  },
  $_getOrgGross() {
    const url = `/org/${OrgId}/paysettings/earnings/gross`;
    return AxiosORGS.get(url);
  },
  $_getEmployeePaySetting(payload) {
    const url = `/org/${OrgId}/employees/${payload.employeeId}/paysettings`;
    return AxiosORGS.get(url);
  },
  $_payItemSetting(payload) {
    const url = "/org/paysettings";
    return AxiosORGS.post(url, payload);
  },
  $_validateNameInPayslip(payload) {
    const url = "/org/payitem/validate";
    return AxiosORGS.post(url, payload);
  },
  $_saveEmployeepayItem(payload) {
    const url = "/paysettings/employees/payitems/save";
    return AxiosHumanar.patch(url, payload);
  },

  // Request settings
  $_getLeaveSetting() {
    const url = `leave/settings/org/${OrgId}`;
    return AxiosHumanar.get(url);
  },
  $_getOneLeaveSetting(payload) {
    const url = `leave/settings/org/${OrgId}/categories/${payload.id}/levels/${payload.levelId}`;
    return AxiosHumanar.get(url);
  },
  $_leaveCategories(payload) {
    const url = "leave/categories";
    return AxiosHumanar.post(url, payload);
  },
  $_getLeaveCategories() {
    const url = `leave/categories/org/${OrgId}`;
    return AxiosHumanar.get(url);
  },
  $_editLeaveCategoryName(categoryId, payload) {
    const url = `leave/categories/${categoryId}`;
    return AxiosHumanar.patch(url, payload);
  },
  $_editLeaveCategory(payload) {
    const url = `leave/categories/${payload.id}`;
    return AxiosHumanar.patch(url, payload);
  },
  $_getLeaveCategoryLevelsSettings(payload) {
    const url = `/leave/settings/org/${OrgId}/fetch-category/${payload}`;
    return AxiosHumanar.get(url);
  },
  $_getOrgLeaveCategorySettings({ activeId, queryParams }) {
    const url = `/leave/settings/org/${OrgId}/fetch-category/${activeId}`;
    return AxiosHumanar.get(url, { params: queryParams });
  },
  $_getLeavePayItems() {
    const url = `/org/${OrgId}/paysettings/earnings?attributes=id,nameOnPayslip&payItemSlug=leaveAllowance`;
    return AxiosORGS.get(url);
  },
  $_getLeaveLevelsSettings(payload) {
    const url = `leave/settings/org/${OrgId}/categories/${payload}/general`;
    return AxiosHumanar.get(url);
  },
  $_getOneLeaveCategorySetting(payload) {
    const url = `/leave/settings/org/${OrgId}/category/${payload}`;
    return AxiosHumanar.get(url);
  },
  $_deleteLeaveCategory(payload) {
    const url = `/leave/categories/${payload}`;
    return AxiosHumanar.delete(url);
  },
  $_leaveRequestSetting(payload) {
    const url = "leave/settings";
    return AxiosHumanar.patch(url, payload);
  },
  $_leaveRequestGeneralSetting(payload) {
    const url = "leave/settings/general";
    return AxiosHumanar.patch(url, payload);
  },
  $_leaveRequest(payload) {
    const url = "leave/requests";
    return AxiosHumanar.post(url, payload);
  },
  $_getOneLeaveRequest(payload) {
    const url = `leave/requests/employees/${payload}`;
    return AxiosHumanar.get(url);
  },
  $_getLeaveRequest() {
    const url = `leave/requests/org/${OrgId}`;
    return AxiosHumanar.get(url);
  },
  $_applyForAdvance(payload) {
    const url = "salary-advance/requests";
    return AxiosHumanar.post(url, payload);
  },
  $_getEmployeeAdvance(payload) {
    const url = `salary-advance/requests/employee/${payload.id}`;
    return AxiosHumanar.get(url);
  },
  $_getAdvanceRequest() {
    const url = `salary-advance/requests/org/${OrgId}`;
    return AxiosHumanar.get(url);
  },
  $_getAllPfaMasterlist() {
    const url = "pfa/list";
    return AxiosHumanar.get(url);
  },
  $_getAllPfaOrglist() {
    const url = `pfa/org/${OrgId}`;
    return AxiosHumanar.get(url);
  },
  $_getPayrollPensionSummary(query) {
    const url = `payroll/pensions/${OrgId}${query}`;
    return AxiosHumanar.get(url);
  },
  $_getPfaPayruns(pfaId) {
    const url = `payroll/pension/pfa/${pfaId}`;
    return AxiosHumanar.get(url);
  },
  $_getRemitPayrunPfa({ pfaId }) {
    const url = `payroll/pension/pfa/${OrgId}/payruns/${pfaId}`;
    return AxiosHumanar.get(url);
  },
  $_getPFAEmployees(pfaNo, pfaId) {
    const url = `payroll/pension/${pfaId}/payruns/${pfaNo}`;
    return AxiosHumanar.get(url);
  },
  $_getPAYEEmployees(pfaNo, pfaId) {
    const url = `payroll/paye/${pfaId}/payruns/${pfaNo}`;
    return AxiosHumanar.get(url);
  },
  $_getNHFEmployees(id) {
    const url = `payroll/employees-nhf/${id}`;
    return AxiosHumanar.get(url);
  },
  $_getOrgPensionSettings() {
    const url = `${OrgId}/pension-settings`;
    return AxiosHumanar.get(url);
  },
  $_getNHF(query) {
    const url = `/payroll/nhf/${OrgId}${query}`;
    return AxiosHumanar.get(url);
  },
  $_getPaye(query) {
    const url = `payroll/payes/${OrgId}${query}`;
    return AxiosHumanar.get(url);
  },
  $_getPayePayrun(irsId) {
    const url = `payroll/paye/sirs/${irsId}`;
    return AxiosHumanar.get(url);
  },
  $_getCycleTaxAuthority(irsId) {
    const url = `payroll/payes/${OrgId}/sirs/${irsId}`;
    return AxiosHumanar.get(url);
  },
  $_getEmployeePaye({ irsId, payrunNo }) {
    const url = `payroll/paye/${irsId}/payruns/${payrunNo}`;
    return AxiosHumanar.get(url);
  },
  $_addPfa(payload) {
    const url = "pfa/org";
    return AxiosHumanar.post(url, payload);
  },
  $_editPfa(payload) {
    const url = "pfa/org";
    return AxiosHumanar.patch(url, payload);
  },
  $_deletePfa(payload) {
    const url = `pfa/org/${payload}`;
    return AxiosHumanar.delete(url);
  },
  $_getPreviousPaye(payruNo) {
    const url = `paye/${payruNo}`;
    return AxiosHumanar.get(url);
  },
  $_createPensionSettings(payload) {
    const url = "/org/pension/settings";
    return AxiosORGS.patch(url, payload);
  },
  $_getNhfSettings() {
    const url = `/compliance-setting/nhf/${OrgId}`;
    return AxiosHumanar.get(url);
  },
  $_saveNhfSettings(payload) {
    const url = `/compliance-setting/nhf`;
    return AxiosHumanar.post(url, payload);
  },
  $_getPayeSettings() {
    const url = `/compliance-setting/paye/${OrgId}`;
    return AxiosHumanar.get(url);
  },
  $_savePayeSettings(payload) {
    const url = `/compliance-setting/paye`;
    return AxiosHumanar.post(url, payload);
  },
  $_getOrgVariableSetting() {
    const url = `${OrgId}/variable_settings`;
    return AxiosHumanar.get(url);
  },
  $_getVariablePay(payload) {
    const url = `/org/${OrgId}/variable-pay/${payload}`;
    return AxiosORGS.get(url);
  },
  $_getPayrunMonths(params) {
    const url = `payroll/payrun-months/${OrgId}${params}`;
    return AxiosHumanar.get(url);
  },
  $_getEmployeeVariablePay(payload) {
    const url = `/org/variable-pay/employees/${payload.id}/${payload.month}/${payload.year}`;
    return AxiosORGS.get(url);
  },
  $_saveVariableEarning(payload) {
    const url = "/org/variable-pay/add-to-payrun";
    return AxiosORGS.post(url, payload);
  },
  $_updateVariableSetting(variableSettingsPayload, id) {
    // TODO refactor
    const url = `${OrgId}/variable_settings/${id}`;
    return AxiosHumanar.put(url, variableSettingsPayload);
  },
  $_get13MonthSetup() {
    const url = `/org/${OrgId}/13th-month-settings`;
    return AxiosORGS.get(url);
  },
  $_save13MonthSetup(payload) {
    const url = `/org/${OrgId}/13th-month-settings`;
    return AxiosORGS.patch(url, payload);
  },
  $_getEmployeeLeaveSettings(payload) {
    const url = `leave/settings/org/${OrgId}/categories/${payload.category}/levels/${payload.levels}/employees/${payload.employee}`;
    return AxiosHumanar.get(url);
  },
  $_updateEmployeeLeaveSettings(payload) {
    const url = "leave/settings/employees";
    return AxiosHumanar.patch(url, payload);
  },
  $_getEmployeeAdvanceSettings(payload) {
    const url = `salary-advance/settings/org/${OrgId}/levels/${payload.level}/employees/${payload.employee}`;
    return AxiosHumanar.get(url);
  },
  $_updateEmployeeSalaryAdvanceSettings(payload) {
    const url = "salary-advance/requests";
    return AxiosHumanar.patch(url, payload);
  },
  $_getOrgTaxAuthorityTins(query) {
    const url = `/org/${OrgId}/tax/tins?${validateParam(query)}`;
    return AxiosORGS.get(url);
  },
  $_addOrgTaxAuthorityTin(payload) {
    const url = "/org/tax/tins";
    return AxiosORGS.post(url, payload);
  },
  $_deleteTaxAuthorityTin(payload) {
    const url = `/org/tax/tins/${payload}`;
    return AxiosORGS.delete(url);
  },

  // documents
  $_getCompanyDocument(payload) {
    // let url = `/documents/${OrgId}/company`;
    // return AxiosHumanar.get(url);
    if (payload) {
      const { search, folders } = payload;
      return AxiosHumanar.get(
        `/documents/${OrgId}/company?folders=${folders}&search=${search}`
      );
    }
    return AxiosHumanar.get(`/documents/${OrgId}/company`);
  },
  $_getCompanyDocumentFilterFolders(folders) {
    const url = `/documents/${OrgId}/company?folders=${folders}`;
    return AxiosHumanar.get(url);
  },
  $_getCompanyDocumentSearch(search) {
    const url = `/documents/${OrgId}/company?search=${search}`;
    return AxiosHumanar.get(url);
  },
  $_createFolder(payload) {
    const url = "/documents/folders";
    return AxiosHumanar.post(url, payload);
  },
  $_uploadFile(payload) {
    const url = "http://35.177.48.27:8000/hr/documents/files";
    return axios({
      method: "post",
      url,
      data: payload,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  $_deleteFile(payload) {
    const url = "/documents/files";
    return AxiosHumanar.delete(url, { data: { fileId: payload } });
  },
  $_downloadFile(payload) {
    const url = `http://35.177.48.27:5001/document-management/document/${payload}`;
    return axios.get(url);
  },
  $_getCompanyRootFolder() {
    const url = `/documents/${OrgId}/company/folders`;
    return AxiosHumanar.get(url);
  },
  $_getCompanyFolderContent(payload) {
    const url = `/documents/company/folders/${payload}/contents`;
    return AxiosHumanar.get(url);
  },
  $_assignFolderToEmployee(payload) {
    const url = "/documents/employees/folders";
    return AxiosHumanar.post(url, payload);
  },
  $_getEmployeesDocuments() {
    const url = `/documents/${OrgId}/employees`;
    return AxiosHumanar.get(url);
  },
  $_getEmployeesDocumentSearch(search) {
    const url = `/documents/${OrgId}/employees?search=${search}`;
    return AxiosHumanar.get(url);
  },
  $_getEmployeesFolders() {
    const url = `/documents/${OrgId}/employees/folders`;
    return AxiosHumanar.get(url);
  },
  $_getOneEmployeeFolders(payload) {
    const url = `/documents/employees/${payload}/folders`;
    return AxiosHumanar.get(url);
  },
  $_editFolder(payload) {
    const url = "/documents/folders";
    return AxiosHumanar.patch(url, payload);
  },
  $_deleteFolder(payload) {
    const url = "/documents/folders";
    return AxiosHumanar.delete(url, { data: { fileId: payload } });
  },
  $_getDocStorage() {
    const url = `/documents/org/${OrgId}/storage`;
    return AxiosHumanar.get(url);
  },
  $_getDocSetting() {
    const url = `/documents/org/${OrgId}/settings`;
    return AxiosHumanar.get(url);
  },
  $_saveDocSettings(payload) {
    const url = `/documents/org/${OrgId}/settings`;
    return AxiosHumanar.patch(url, payload);
  },

  // AAA Endpoints
  $_checkEmailExist(payload) {
    const url = `/org/${OrgId}/email/${payload}/check`;
    return AxiosORGS.get(url);
  },
  $_getFunction() {
    const url = `/org/${OrgId}/functions`;
    return AxiosORGS.get(url);
  },
  $_getFunctionDesignations() {
    const url = `/org/${OrgId}/functions/designations`;
    return AxiosORGS.get(url);
  },
  $_getPeopleSearch(payload) {
    const url = `/org/${OrgId}/none-app-users/ess?search=${payload}`;
    return AxiosORGS.get(url);
  },
  $_getOrgPeople(payload) {
    const url = `/org/${OrgId}/none-app-users/humanar?search=${payload}`;
    return AxiosORGS.get(url);
  },
  $_createEmployeeFromPeople(payload) {
    const url = "/users/ess/from-people";
    return AxiosORGS.post(url, payload);
  },
  $_getFunctionId(payload) {
    const url = `/org/functions/${payload}`;
    return AxiosORGS.get(url);
  },
  $_getFunctionUsers({ functionId }) {
    const url = `/org/functions/${functionId}/users`;
    return AxiosORGS.get(url);
  },
  $_getHierarchy() {
    const url = `/org/${OrgId}/hierarchies`;
    return AxiosORGS.get(url);
  },
  $_getTag() {
    const url = `/org/${OrgId}/levels/tags`;
    return AxiosORGS.get(url);
  },
  $_getCategory() {
    const url = `/org/${OrgId}/level-categories`;
    return AxiosORGS.get(url);
  },
  $_getFunctionKpis(payload) {
    const url = `/org/${OrgId}/functions?include=kpis${payload}`;
    return AxiosORGS.get(url);
  },
  $_editFunction(payload) {
    const url = "/org/functions";
    return AxiosORGS.patch(url, payload);
  },
  $_deleteFunction(payload) {
    const url = `/org/functions/${payload}`;
    return AxiosORGS.delete(url);
  },
  $_createFunction(payload) {
    const url = "/org/functions";
    return AxiosORGS.post(url, payload);
  },
  $_createLevel(payload) {
    const url = "/org/levels";
    return AxiosORGS.post(url, payload);
  },
  $_getLevels() {
    const url = `/org/${OrgId}/levels`;
    return AxiosORGS.get(url);
  },
  $_getOneLevel(payload) {
    const url = `/org/levels/${payload.levelId}`;
    return AxiosORGS.get(url);
  },
  $_updateLevel(payload) {
    const url = "/org/levels";
    return AxiosORGS.patch(url, payload);
  },
  $_deleteLevel(payload) {
    const url = "/org/levels";
    return AxiosORGS.delete(url, { headers: $simpleAuthHeader, data: payload });
  },
  $_updateLevelCategory(payload, id) {
    const url = `/org/${OrgId}/level-categories/${id}`;
    return AxiosHumanar.patch(url, payload);
  },
  $_addTagCategory(payload, levelId) {
    const url = `/org/${OrgId}/level-categories/${levelId}/tags`;
    return AxiosHumanar.post(url, payload);
  },
  $_deleteCategory(id) {
    const url = `/org/${OrgId}/level-categories/${id}`;
    return AxiosHumanar.delete(url);
  },
  $_addCategory(payload) {
    const url = `/org/${OrgId}/level-categories`;
    return AxiosHumanar.post(url, payload);
  },
  $_getDesignations(payload) {
    const url = `/org/${OrgId}/designations/humanar?${payload}`;
    return AxiosORGS.get(url);
  },
  $_getDesignationsWithPayband(payload) {
    const url = `/org/${OrgId}/designations/humanar?${payload}`;
    return AxiosORGS.get(url);
  },
  $_getOneDesignations(payload) {
    const url = `/org/designations/${payload}`;
    return AxiosORGS.get(url);
  },
  $_createDesignation(payload) {
    const url = "/org/designations";
    return AxiosORGS.post(url, payload);
  },
  $_updateDesignation(payload) {
    const url = "/org/designations";
    return AxiosORGS.patch(url, payload);
  },
  $_deleteDesignation(payload) {
    const url = "/org/designations";
    return AxiosORGS.delete(url, { headers: $simpleAuthHeader, data: payload });
  },
  $_rolesPrivileges() {
    const url = "/roles/privileges/hr";
    return AxiosORGS.get(url);
  },
  $_NewRolesPrivileges() {
    const url = "/apps/humanar/privileges";
    return AxiosAAA.get(url);
  },
  $_getOrgRoles() {
    const url = `/roles/hr/${OrgId}`;
    return AxiosORGS.get(url);
  },
  $_delOrgRoles(payload) {
    const url = `/roles/${payload}`;
    return AxiosORGS.delete(url);
  },
  $_NewGetOrgRoles(query) {
    const url = `/roles/${OrgId}/humanar?${query}`;
    return AxiosORGS.get(url);
  },
  $_NewCreateRoles(payload) {
    const url = "/roles";
    return AxiosORGS.post(url, payload);
  },
  $_createRoles(payload) {
    const url = "/roles/hr";
    return AxiosORGS.post(url, payload);
  },
  $_NewUpdateRoles(payload) {
    const url = "/roles";
    return AxiosORGS.patch(url, payload);
  },
  $_updateRoles(payload) {
    const url = "/roles/privileges/hr/update";
    return AxiosORGS.patch(url, payload);
  },
  $_NewGetUpdateRoles(payload) {
    const url = `/roles/${payload}/privileges`;
    return AxiosORGS.get(url);
  },
  $_getUpdateRoles(payload) {
    const url = `/roles/${payload}/privileges/hr`;
    return AxiosORGS.get(url);
  },
  $_getOrgStructure() {
    const url = `/org/${OrgId}/functions/structure`;
    return AxiosORGS.get(url);
  },
  $_getOrgChart() {
    const url = `/org/${OrgId}/org-chart`;
    return AxiosORGS.get(url);
  },
  $_getSubscriptionProduct() {
    const url = `/apps/activesubscription/${OrgId}`;
    return AxiosORGS.get(url);
  },
  $_getEmployeeApp(payload) {
    const url = `subscription/org/${OrgId}/employees/${payload}/apps`;
    return AxiosORGS.get(url);
  },
  $_createEmployeeApp(payload) {
    const url = "subscription/product/hr/employees/apps";
    return AxiosORGS.post(url, payload);
  },
  $_logout() {
    const url = "/auth/logout";
    return AxiosORGS.post(url);
  },
  $_getUser(userId) {
    const url = `/users/${userId}?attributes=id`;
    return AxiosORGS.get(url);
  },
  $_createUser(payload) {
    const url = `/${OrgId}/employees?type=people`;
    return AxiosHumanar.post(url, payload);
  },
  $_createOneUser({ userId }) {
    const url = `/users/${userId}`;
    return AxiosORGS.get(url);
  },
  $_deleteUser({ userId }) {
    const url = `/user/${userId}/delete`;
    return AxiosORGS.delete(url);
  },
  $_updateUser({ payload, userId }) {
    const url = `employees/profile/${userId}?type=people`;
    return AxiosHumanar.patch(url, payload);
  },
  // Groups
  $_createGroups(payload) {
    const url = "/org/groups";
    return AxiosORGS.post(url, payload);
  },
  $_createUserGroups(payload) {
    const url = "/org/groups/users";
    return AxiosORGS.post(url, payload);
  },
  $_getGroups(payload) {
    const url = `/org/${OrgId}/groups?type=${payload}`;
    return  AxiosORGS.get(url);
  },
  $_getFunctionalGroups() {
    const url = `/org/${OrgId}/groups/functional`;
    return AxiosORGS.get(url);
  },
  $_getUserGroups(payload) {
    const url = `/org/${OrgId}/groups/users/${payload}`;
    return AxiosORGS.get(url);
  },
  $_getOneGroup(payload) {
    const url = `/org/groups/${payload}/members`;
    return AxiosORGS.get(url);
  },
  $_editOneGroup(payload) {
    const url = "/org/groups";
    return AxiosORGS.patch(url, payload);
  },
  $_makeMemberAdmin(payload) {
    const url = "/org/groups/member/admin-status";
    return AxiosORGS.patch(url, payload);
  },
  $_addMemberToGroup(payload) {
    const url = "/org/groups/members";
    return AxiosORGS.post(url, payload);
  },
  $_deleteMemberFromGroup(payload) {
    const url = `/org/groups/members/${payload}`;
    return AxiosORGS.delete(url);
  },
  $_deleteGroup(payload) {
    const url = `/org/groups/${payload}`;
    return AxiosORGS.delete(url);
  },
  // security
  $_createPasswordPolicy(payload) {
    const url = "/org/security/passwords/policy";
    return AxiosORGS.patch(url, payload);
  },
  $_getPasswordPolicy() {
    const orgId = this.OrgId;
    const url = `/org/${orgId}/security/passwords/policy`;
    return AxiosORGS.get(url);
  },
  $_getSecurityQuestion() {
    const url = `/org/${OrgId}/security/questions`;
    return AxiosORGS.get(url);
  },
  $_getActiveSecurityQuestion() {
    const url = `/org/${OrgId}/security/questions/active`;
    return AxiosORGS.get(url);
  },
  $_updateSecurityQuestionAnswer(payload) {
    const url = "/org/security/questions/answers";
    return AxiosORGS.patch(url, payload);
  },
  $_enableSecurityQuestion(payload) {
    const url = "/org/security/questions/enable";
    return AxiosORGS.patch(url, payload);
  },
  $_disableSecurityQuestion(payload) {
    const url = "/org/security/questions/disable";
    return AxiosORGS.patch(url, payload);
  },
  $_updateSecurityQuestion(payload) {
    const url = "/org/security/questions/active";
    return AxiosORGS.patch(url, payload);
  },
  $_createSecurityQuestion(payload) {
    const url = "/org/security/questions";
    return AxiosORGS.post(url, payload);
  },
  $_enableRingFencing(payload) {
    const url = "/org/security/ringfencing/enable";
    return AxiosORGS.patch(url, payload);
  },
  $_disableRingFencing(payload) {
    const url = "/org/security/ringfencing/disable";
    return AxiosORGS.patch(url, payload);
  },
  $_disableTwoFactor(payload) {
    const url = "/org/security/2fa/status/off";
    return AxiosORGS.post(url, payload);
  },
  $_twoFactorSetUp(payload) {
    const url = "/org/security/2fa/settings";
    return AxiosORGS.post(url, payload);
  },
  $_sendTwoFactorCode(payload) {
    const url = "/org/security/2fa/setup/otp";
    return AxiosORGS.post(url, payload);
  },
  $_sendAdminTwoFactorCode(payload) {
    const url = "/org/security/passwords/confirm-root-admin";
    return AxiosORGS.post(url, payload);
  },
  $_transferAdminRights(payload) {
    const url = "/org/change-root-admin";
    return AxiosORGS.post(url, payload);
  },
  $_getTwoFactorStatus() {
    const url = `/org/${OrgId}/security/2fa/status`;
    return AxiosORGS.get(url);
  },
  $_getSecurityQuestionStatus() {
    const url = `/org/${OrgId}/security/questions/status`;
    return AxiosORGS.get(url);
  },
  $_answerSecurityQuestion(payload) {
    const url = "/org/security/questions/answers";
    return AxiosORGS.patch(url, payload);
  },
  $_getRingFencingStatus() {
    const orgId = this.OrgId;
    const url = `/org/${orgId}/security/ringfencing/status`;
    return AxiosORGS.get(url);
  },
  $_getAllIp() {
    const orgId = this.OrgId;
    const url = `/org/${orgId}/security/ringfencing`;
    return AxiosORGS.get(url);
  },
  $_addIp(payload) {
    const url = "/org/security/ringfencing";
    return AxiosORGS.post(url, payload);
  },
  $_deleteIpAddress(payload) {
    const url = `/org/security/ringfencing/${payload}`;
    return AxiosORGS.delete(url);
  },
  $_getPermissions(payload) {
    const url = `/get-all-employees/${payload}`;
    return AxiosHumanar.get(url);
  },
  $_getLevelPermissions(payload) {
    const url = `/permissions/levels/${payload}`;
    return AxiosHumanar.get(url);
  },
  $_savePermissions(payload) {
    const url = "/permissions";
    return AxiosHumanar.post(url, payload);
  },
  $_saveLevelPermissions(payload) {
    const url = "/permissions/levels";
    return AxiosHumanar.post(url, payload);
  },
  $_getAdminUsers(app, grpAttr) {
    const url = `/org/${OrgId}/users?app=${app}&attributes=${grpAttr}`;
    return AxiosORGS.get(url);
  },
  $_getEssPermissions() {
    const url = `/permissions/ess/${OrgId}`;
    return AxiosHumanar.get(url);
  },
  $_saveEssPermissions(payload) {
    const url = `/permissions/ess`;
    return AxiosHumanar.post(url, payload);
  },
  $_getRootUser() {
    const url = `/users/${OrgId}/root`;
    return AxiosORGS.get(url);
  },
  $_updateRootUser(payload) {
    const url = `/user/${payload.id}/update`;
    return AxiosORGS.patch(url, payload);
  },
  changeOrgRootUser(payload) {
    const url = "/org/change-root-admin";
    return AxiosORGS.patch(url, payload);
  },
  $_getIndustries() {
    const url = "/utilities/industries/subIndustries";
    return AxiosORGS.get(url);
  },
  $_updateOrgPhoneNumber(payload) {
    const url = "/org/update/phone";
    return AxiosORGS.patch(url, payload);
  },
  $_updateOrgEmail(payload) {
    const url = "/org/update/email";
    return AxiosORGS.patch(url, payload);
  },
  $_updatePassword(payload) {
    const url = "/auth/change-password";
    return AxiosORGS.post(url, payload);
  },
  // Bank Accounts
  $_getBanks() {
    const url = `/payments/v1/banks`;
    return AxiosAAA.get(url);
  },
  $_verifyAccount(bankCode, accountNumber) {
    const url = `/payments/v1/banks/verify/${bankCode}/${accountNumber}`;
    return AxiosAAA.get(url);
  },
  $_saveAccount(payload) {
    const url = "/payments/v1/direct-debit/accounts";
    return AxiosAAA.post(url, payload);
  },
  //  Approval Flow
  $_getApproval() {
    const url = `/approvals/v1/actions/humanar/${OrgId} `;
    return AxiosAAA.get(url);
  },
  $_saveApproval(payload) {
    const url = "/approvals/v1/create";
    return AxiosAAA.post(url, payload);
  },
  $_updateApproval({ workflowId, payload }) {
    const url = `/approvals/v1/update/${workflowId}`;
    return AxiosAAA.patch(url, payload);
  },
  $_getAllWorkflow(actionId) {
    const url = `/approvals/v1/all/${OrgId}/${actionId} `;
    return AxiosAAA.get(url);
  },
  $_retrieveSingleWorkflow(actionId) {
    const url = `/approvals/v1/one/${actionId} `;
    return AxiosAAA.get(url);
  },
  $_updateWorkflow(url, payload) {
    const set = `/approvals/v1/update/${url} `;
    return AxiosAAA.patch(set, payload);
  },
  $_removeWorkflowFromList(actionId) {
    const url = `/approvals/v1/delete/${actionId} `;
    return AxiosAAA.delete(url);
  },
  $_getLeaveAbsence(payload) {
    const url = `dashboard/get-employees-on-leave/${payload}`;
    return AxiosHumanar.get(url);
  },
  $_getEmployeeBirthdays(params) {
    const url = `dashboard/${OrgId}/get-employees-birthday?period=${params}`;
    return AxiosORGS.get(url);
  },
  $_getEmployeeWorkAnniversary(params) {
    const url = `dashboard/${OrgId}/get-employees-work-anniversary?period=${params}`;
    return AxiosORGS.get(url);
  },
  $_getEmployeeMovements(params) {
    const url = `dashboard/employee-movements/${OrgId}?action=${params}`;
    return AxiosHumanar.get(url);
  },
  $_getEmployeeCounts() {
    const url = `dashboard/employee-counts/${OrgId}`;
    return AxiosHumanar.get(url);
  },
  $_getEmployeeCost(params) {
    const url = `dashboard/employee-costs/${OrgId}?paymentType=${params}`;
    return AxiosHumanar.get(url);
  },
  $_getPendingApproval() {
    const url = `dashboard/pending-approval/${OrgId}`;
    return AxiosHumanar.get(url);
  },
  $_countEmployeegrowth() {
    const url = `/dashboard/employee-counts/growth/${OrgId}`;
    return AxiosHumanar.get(url);
  },
  $_countEmployeeMovement(params) {
    const url = `/dashboard/employee-movements/growth/${OrgId}?action=${params}`;
    return AxiosHumanar.get(url);
  },
  $_sendOTP(payload) {
    const url = `/org/security/2fa/otp`;
    return AxiosORGS.post(url, payload);
  },
  $_employeesLeaveCount() {
    const url = `/leave/employees-on-leave/${OrgId}`;
    return AxiosHumanar.get(url);
  },
  $_employeesWorkCount() {
    const url = `/leave/employees-at-work/${OrgId}`;
    return AxiosHumanar.get(url);
  },
  $_employeeCostGrowth(params) {
    const url = `/dashboard/employee-costs/growth/${OrgId}?paymentType=${params}`;
    return AxiosHumanar.get(url);
  },
  $_getPaySlipDetails(payslipId) {
    const url = `/payroll/employee/payslip/${payslipId}`;
    return AxiosHumanar.get(url);
  },
  $_getPaySlipYearlyEarnings(userId) {
    const url = `/payroll/employee/yearly-earnings/${userId}`;
    return AxiosHumanar.get(url);
  },
  $_getPaySlipMonthlyEarnings(userId) {
    const url = `/payroll/employee/monthly-earnings/${userId}`;
    return AxiosHumanar.get(url);
  },
  $_filterPaySlips(userId, params) {
    const url = `/payroll/employee/duration-payslips/${userId}?${params}`;
    return AxiosHumanar.get(url);
  },
  $_getOrgPayBand() {
    const url = `/paybands/all-payband/${OrgId}`;
    return AxiosHumanar.get(url);
  },
  $_createOrgPayBand(payload) {
    const url = `/paybands/create-payband`;
    return AxiosHumanar.post(url, payload);
  },
  $_getLevelPayBand(levelId) {
    const url = `/paybands/get-payband/${levelId}`;
    return AxiosHumanar.get(url);
  },
  $_updatePayBand(payBandId, payload) {
    const url = `/paybands/update-payband/${payBandId}`;
    return AxiosHumanar.patch(url, payload);
  },
  $_deletePayBand(payBandId) {
    const url = `/paybands/delete-payband/${payBandId}`;
    return AxiosHumanar.delete(url);
  },
  $_getGeoLocationData(address) {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${process.env.VUE_APP_GOOGLE_API}`;
    return axios.get(url);
  },
  $_createMultiOrganisation(payload) {
    const url = `/internal/auth/create-multi-org`;
    return AxiosORGS.post(url, payload);
  },
  $_getMultiOrganisation(id) {
    const url = `/multi-org`;
    return AxiosORGS.get(url, { headers: { userid: id } });
  },
  $_switchOrganisation(id, payload) {
    const url = `/auth/switch-org/${id}`;
    return AxiosORGS.post(url, payload);
  },
  $_checkDomain(value) {
    const url = `/auth/signup/check-alias/${value}`;
    return AxiosORGS.get(url);
  },
  $_loginAccount(payload) {
    const url = `/auth/login`;
    return AxiosORGS.post(url, payload);
  },
  $_reactivateOrganization(payload) {
    const url = `/reactivateOrg`;
    return AxiosAAA.patch(url, payload);
  },
  $_deactivateOrganization(payload) {
    const url = `/deactivateOrg`;
    return AxiosAAA.patch(url, payload);
  },
  $_getActiveMultiOrganisation(id, slug) {
    const url = `/multi-org?status=${slug}`;
    return AxiosAAA.get(url, { headers: { userid: id } });
  },
  $_verifyOrgOTP(payload) {
    const url = `/verifymultiorg/user`;
    return AxiosORGS.post(url, payload);
  },
  $_updateMultiOrg(payload) {
    const url = `/update/multiorg`;
    return AxiosAAA.patch(url, payload);
  },
  $_groupMultiOrg(payload) {
    const url = `/groupstructure`;
    return AxiosAAA.post(url, payload);
  },
  $_getMonthlyEarnings(month, year, userId) {
    const url = `/payroll/info/monthly/${OrgId}/${userId}?month=${month}&year=${year}`;
    return AxiosHumanar.get(url);
  },
  $_getAnnualEarnings(year, userId) {
    const url = `/payroll/info/yearly/${OrgId}/${userId}?year=${year}`;
    return AxiosHumanar.get(url);
  },
  $_createJobGrade(payload) {
    const url = `/org/${OrgId}/job-grades`;
    return AxiosORGS.post(url, payload);
  },
  $_getJobGrade() {
    const url = `/org/${OrgId}/job-grades`;
    return AxiosORGS.get(url);
  },
  $_editJobGrade(id, payload) {
    const url = `/org/${OrgId}/job-grades/${id}`;
    return AxiosORGS.patch(url, payload);
  },
  $_deleteJobGrade(id) {
    const url = `/org/${OrgId}/job-grades/${id}`;
    return AxiosORGS.delete(url);
  }


};
export default apiFactory;
