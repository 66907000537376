/* eslint-disable import/prefer-default-export */
import { AxiosHumanar, AxiosAAA, AxiosAPP, AxiosORGS, OrgId, $AuthUser, AxiosCloudenly } from "./orgRepository";

export const validateParam = param => {
  if (!param || !param.length) return "";
  return param;
};
export const employeeApi = {
  $_getAllEmployees(attributes) {
    const url = `/${OrgId}/employees?attributes=${attributes}`;
    return AxiosCloudenly.get(url);
  },
  $_getAllEmployeesWithQueries(queries) {
    const url = `/org/${OrgId}/employees?${queries}`;
    return AxiosORGS.get(url);
  },
  $_getAllEmployeesEss(queries) {
    const url = `/org/directory/${OrgId}/employees?${queries}`;
    return AxiosORGS.get(url);
  },
  $_getEmployeeCount() {
    const url = `/apps/count/activesubscription/${OrgId}`;
    return AxiosHumanar.get(url);
  },
  $_getAllDirectReportingWithQueries(queries) {
    const url = `/employees/manager/${$AuthUser.id}/?${queries}`;
    return AxiosCloudenly.get(url);
  },
  $_getOrganisationEmployees(params) {
    const url = `/org/${OrgId}/employees`;
    return AxiosORGS.get(url, { params });
  },
  $_getEssDirectoryDetails(userId) {
    const url = `/employees/ess/profile/${userId}`;
    return AxiosCloudenly.get(url);
  },
  $_getOneEmployeeAll({ id }) {
    const url = `${OrgId}/employees/${id}?type=all`;
    return AxiosCloudenly.get(url);
  },
  $_getOneEmployeePersonalInfo( id ) {
    const url = `${OrgId}/employees/${id}/personal-info`;
    return AxiosCloudenly.get(url);
  },
  $_getOneEmployeeEmploymentInfo({id} ) {
    const url = `${OrgId}/employees/${id}/employment-info`;
    return AxiosCloudenly.get(url);
  },
  $_getOneEmployeePaymentInfo( id ) {
    const url = `${OrgId}/employees/${id}/payment-info`;
    return AxiosCloudenly.get(url);
  },
  $_getEmployeeSalaryBreakDown({ id }) {
    const url = `/employees/salary-breakdown/${id}`;
    return AxiosCloudenly.get(url);
  },
  $_getSuspensionReason() {
    const url = `/suspension-reasons/${OrgId}`;
    return AxiosCloudenly.get(url);
  },
  $_getEmployeeInvite(payload) {
    const url = `${OrgId}/employees/invite?${validateParam(payload)}`;
    return AxiosHumanar.get(url);
  },
  $_inviteEmployee(payload) {
    const url = `/org/${OrgId}/employees/invite`;
    return AxiosORGS.post(url, payload);
  },
  $_getProgressBar(progressId) {
    const url = `${progressId}/employees-creation-progress`;
    return AxiosCloudenly.get(url);
  },
  $_importEmployee(payload) {
    const url = `/org/detailed/import/employee`;
    return AxiosORGS.post(url, payload);
  },
  $_createEmployee(payload) {
    const url = `${OrgId}/employees`;
    return AxiosCloudenly.post(url, payload);
  },
  $_employeeActions(type, payload) {
    const url = `/employee-action/${type}`;
    return AxiosCloudenly.post(url, payload);
  },
  $_employeeCredentialUpdate(payload) {
    const url = `/org/security/resend-credentials`;
    return AxiosORGS.post(url, payload);
  },
  $_employeeCertifications(id) {
    const url = `${OrgId}/employees/${id}/educational-info`;
    return AxiosCloudenly.get(url);
  },
  $_createEmployeeCertifications(payload, id) {
    const url = `${OrgId}/employees/${id}/educational-info`;
    return AxiosCloudenly.post(url, payload);
  },
  $_unsuspendEmployeeAction(userId) {
    const url = `/employee-action/unsuspend/${userId}`;
    return AxiosCloudenly.patch(url);
  },
  $_getOrgSuspendedEmployees(queryParams) {
    const url = `get/suspended/employee/${OrgId}`;
    return AxiosCloudenly.get(url, { params: { ...queryParams } });
  },
  $_getOrgDisengagedEmployees(queryParams) {
    const url = `get/disengaged/employee/${OrgId}`;
    return AxiosCloudenly.get(url, { params: { ...queryParams } });
  },
  $_saveSettingPreference(userId, payload) {
    const url = `/employees/setting-preferences/${userId}`;
    return AxiosCloudenly.patch(url, payload);
  },
  $_reengageEmployee(userId) {
    const url = `/employee-action/reengage/${userId}`;
    return AxiosCloudenly.patch(url);
  },
  $_suspensionReason(payload) {
    const url = `/suspension-reason`;
    return AxiosCloudenly.post(url, payload);
  },
  $_editOneEmployee({ id, employeeData }, query = "type=bio") {
    const url = `${OrgId}/employees/${id}?${query}`;
    return AxiosCloudenly.patch(url, employeeData);
  },
  $_saveEmployeeBankInfo({ userId, data }) {
    const url = `/employees/bank-info/${userId}`;
    return AxiosCloudenly.patch(url, data);
  },
  $_saveEmployeeTaxInfo({ userId, data }) {
    const url = `/employees/tax-info/${userId}`;
    return AxiosCloudenly.patch(url, data);
  },
  $_saveEmployeePensionInfo({ userId, data }) {
    const url = `/employees/pension-info/${userId}`;
    return AxiosCloudenly.patch(url, data);
  },
  $_editEmployeeByType({ type, id, payload }) {
    const url = `/employees/${type}/${id}`;
    return AxiosCloudenly.patch(url, payload);
  },
  $_checkAccountNo({ bankCode, acctNumber }) {
    const url = `/payments/v1/banks/verify/${bankCode}/${acctNumber}`;
    return AxiosAAA.get(url);
  },
  $_employeeMovements(params, userId) {
    const url = `dashboard/employee/movements/${userId}?action=${params}`;
    return AxiosCloudenly.get(url);
  },
  $_deleteEmployee(userId) {
    const url = `/employees/${userId}`;
    return AxiosCloudenly.delete(url);
  },
  $_employeeLeave(userId) {
    const url = `dashboard/employee/leave/${userId}`;
    return AxiosCloudenly.get(url);
  },
  $_getUserCount() {
    const url = `/subscriptions/users/${OrgId}`;
    return AxiosCloudenly.get(url);
  },
  $_employeePendingApproval(userId) {
    const url = `dashboard/employee/pending-approvals/${userId}`;
    return AxiosCloudenly.get(url);
  },
  $_employeeMovementsGrowth(params, userId) {
    const url = `dashboard/employee-movements/ess/growth/${userId}?action=${params}`;
    return AxiosCloudenly.get(url);
  },

  $_getEmployeesLocationDistribution() {
    const url = `/analytics/location-distribution/${OrgId}`;
    return AxiosHumanar.get(url);
  },
  $_getOrgEmploymentTypes() {
    const url = `/employment-type/${OrgId}`;
    return AxiosHumanar.get(url);
  },
  $_getHumanarEmploymentTypes() {
    const url = `/employment-type/`;
    return AxiosHumanar.get(url);
  },
  $_employeeOnProbation(queries) {
    const url = `get/employee/all-confirm-assessment/${OrgId}?${queries}`;
    return AxiosCloudenly.get(url);
  },
  $_saveEmployeeConfirmationAssessment(payload) {
    const url = `employee-action/confirm-assessment`;
    return AxiosCloudenly.post(url, payload);
  },
  $_confirmEmployee(payload) {
    const url = `employee-action/confirm-assessment?&confirm=true`;
    return AxiosCloudenly.post(url, payload);
  },
  $_extendProbation(payload) {
    const url = `employee-action/extend-probation`;
    return AxiosCloudenly.post(url, payload);
  },
  $_getPromotedEmployee(queryParams) {
    const url = `get/promoted/employee/${OrgId}`;
    return AxiosCloudenly.get(url, { params: { ...queryParams } });
  },
  $_getOrgEmployeeRedeployments(queryParams) {
    const url = `get/redeployed/employee/${OrgId}`;
    return AxiosCloudenly.get(url, queryParams);
  },
  $_getPromotedEmployeeDetails(userId) {
    const url = `promoted/employee/details/${userId}`;
    return AxiosCloudenly.get(url);
  },
  $_employeeActionPromoteEmployee({ payload, query }) {
    const url = `employee-action/promote?${query}`
    return AxiosCloudenly.post(url, payload);
  },
  $_employeeActionRedeployeEmployee({ payload, query }) {
    const url = `employee-action/redeployment?${query}`
    return AxiosCloudenly.post(url, payload);
  },
  $_getRedeployedEmployeeDetails(userId) {
    const url = `redeployed/employee/details/${userId}`;
    return AxiosCloudenly.get(url);
  },
  $_getEmployeeEligibility(userId) {
    const url = `employees/elligibilty/${userId}`
    return AxiosCloudenly.get(url)
  },
  $_getOrgEmployeeDelegation(queryParams) {
    const url = `get/delegated/employee/${OrgId}`;
    return AxiosCloudenly.get(url, queryParams);
  },
  $_employeeActionDelegateEmployee({ payload, query }) {
    const url = `employee-action/delegation-management?${query}`
    return AxiosCloudenly.post(url, payload);
  },
  $_unSuspendOrgEmployee(payload) {
    const url = `/employee-action/unsuspend`
    return AxiosCloudenly.post(url, payload);
  },
  $_reEngageOrgEmployee(payload) {
    const url = `/employee-action/reengage`
    return AxiosCloudenly.post(url, payload);
  },
  $_disengageOrgEmployee(payload) {
    const url = `/employee-action/disengage`
    return AxiosCloudenly.post(url, payload);
  },
  $_getPaybandByLevelId(levelId) {
    const url = `paybands/get-payband/${levelId}`;
    return AxiosHumanar.get(url);
  },
  $_getEmployeePayGradeInfo(employeeId) {
    const url = `AxiosCloudenly/pay-information/${employeeId}`;
    return AxiosHumanar.get(url);
  },
  $_getEmployeeApprovalInsights(userId) {
    const url = `/insight/${OrgId}/${userId}`;
    return AxiosAPP.get(url);
  },
  $_getEmployeeFinanceInsights(userId) {
    const url = `/payroll/info/yearly/${OrgId}/${userId}?year=`;
    return AxiosHumanar.get(url)
  },
  $_getEmployeeTimelineInsights(userId) {
    const url = `/employee/timeline/${userId}`;
    return AxiosCloudenly.get(url);
  },
  $_getEmployeeReportingLineInsights(id) {
    const url = `/org/reportline/${id}?others=true`;
    return AxiosORGS.get(url)
  },
  $_getEmployeeInsightRoles(userId) {
    const url = `/user/roles/${OrgId}/${userId}`;
    return AxiosCloudenly.get(url);
  },
  $_getUsersInsightRoles(id, userId) {
    const url = `/roles/users/${id}/${userId}`;
    return AxiosCloudenly.get(url);
  },
  $_deleteRole(userId, id) {
    const url = `/user/roles/${userId}/${id}`;
    return AxiosCloudenly.delete(url);
  },
  $_getEmployeeDirectReportInsights(id) {
    const url = `/org/directline/${id}?others=true`;
    return AxiosORGS.get(url);
  },
  $_deleteEducationQualification(id, userId) {
    const url = `${OrgId}/employees/${userId}/educational-info/${id}`;
    return AxiosCloudenly.delete(url);
  }
};
