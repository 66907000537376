import subscription from "../middleware/isSubscribed";

export default [
    {
        path: "/documents",
        name: "OrganisationCompanyDocuments",
        component: () => import("@/modules/Admin/Documents/index"),
        meta: {
            authRequired: true,
            adminRequire: true,
            middleware: [subscription]
        }
    },
    {
        path: "/documents/employee",
        name: "EmployeeDocs",
        component: () => import("@/modules/Admin/Documents/employeeDocs/index"),
        meta: {
            authRequired: true,
            adminRequire: true,
            middleware: [subscription]
        }
    },
    {
        path: "/documents/employee/:id",
        name: "ViewEmployeeDocs",
        component: () => import("@/modules/Admin/Documents/employeeDocs/employees/viewEmployee/index"),
        meta: {
            authRequired: true,
            adminRequire: true,
            middleware: [subscription]
        }
    },
    {
        path: "/documents/employee/view-folder/:id",
        name: "ViewEmployeeFolder",
        component: () => import("@/modules/Admin/Documents/employeeDocs/documents/viewFolder"),
        meta: {
            authRequired: true,
            adminRequire: true,
            middleware: [subscription]
        }
    },
    {
        path: "/documents/org/recycleBin",
        name: "OrgRecycleBin",
        component: () => import("@/modules/Admin/Documents/recycleBin"),
        meta: {
            authRequired: true,
            adminRequire: true,
            middleware: [subscription]
        }
    },
    {
        path: "/documents/emp/recycleBin",
        name: "EmpRecycleBin",
        component: () => import("@/modules/Admin/Documents/employeeDocs/recycleBin"),
        meta: {
            authRequired: true,
            adminRequire: true,
            middleware: [subscription]
        }
    },
    {
        path: "/documents/storageSettings",
        name: "StorageSettings",
        component: () => import("@/modules/Admin/Documents/storageSettings/index"),
        meta: {
            authRequired: true,
            adminRequire: true,
            middleware: [subscription]
        }
    },
]
