export default [
  {
    path: "/ess/manager-self-service/leave",
    name: "EssMSSLeave",
    component: () => import("@/modules/Ess/ManagerSelfService/benefits/leave"),
    meta: {
      authRequired: true,
      employeeUrl: true
    }
  },
  {
    path: "/ess/leave-management/annual-leave-plan/:leaveYear",
    name: "EssViewAdminAnnualLeavePlan",
    component: () => import("@/modules/Admin/BenefitsModule/Leave/ViewAnnualLeavePlan"),
    meta: {
      authRequired: true,
      employeeUrl: true
    }
  },
  {
    path: "/ess/leave-management/annual-leave-plan/:year/:id",
    name: "EssViewEmployeeAnnualLeavePlan",
    component: () => import("@/modules/Admin/BenefitsModule/Leave/ViewEmployeeAnnualLeavePlan"),
    meta: {
      authRequired: true,
      employeeUrl: true
    }
  },
  {
    path: "/ess/leave-management/employee-annual-leave-plan/conflicts/:year/:startDate/:id/:conflictId",
    name: "EssEmployeeCalendarViewPlan",
    component: () => import("@/modules/Admin/BenefitsModule/Leave/EmployeeCalendarViewPlan"),
    meta: {
      authRequired: true,
      employeeUrl: true
    }
  },
  {
    path: "/ess/leave-management/leave-balance/:id",
    name: "EssViewYearEndLeaveBalance",
    component: () => import("@/modules/Admin/BenefitsModule/Leave/ViewYearEndLeaveBalance"),
    meta: {
      authRequired: true,
      employeeUrl: true
    }
  },
  {
    path: "/ess/manger/overtime",
    name: "ManagerOvertimeRequests",
    component: () => import("@/modules/Ess/ManagerSelfService/benefits/overtime"),
    meta: {
      authRequired: true,
      employeeUrl: true
    }
  },
  {
    path: "/ess/manager/advance-and-loan",
    name: "ManagerAdvanceLoans",
    component: () =>
      import("@/modules/Ess/ManagerSelfService/benefits/advance"),
    meta: {
      authRequired: true,
      employeeUrl: true,
    }
  },
  {
    path: "/ess/manager/direct-reporting",
    name: "ManagerDirectReporting",
    component: () =>
      import("@/modules/Ess/ManagerSelfService/DirectReporting/index"),
    meta: {
      authRequired: true,
      employeeUrl: true,
    }
  },
  {
    path: "/ess/manager/direct-reporting/:id",
    name: "ManagerDirectReportingDetails",
    component: () =>
      import("@/modules/Ess/ManagerSelfService/DirectReporting/index"),
    meta: {
      authRequired: true,
      employeeUrl: true,
    }
  },
  {
    path: "/ess/manager/confirmation",
    name: "EssMSSConfirmation",
    component: () =>
      import("@/modules/Ess/ManagerSelfService/employeeActions/confirmation"),
    meta: {
      authRequired: true,
      employeeUrl: true,
    }
  },
  {
    path: "/ess/manager/confirmation/view-assessment/:id",
    name: "EssViewAssesment",
    component: () =>
      import("@/modules/Admin/EmployeeModule/EmployeeActions/Confirmation/viewAssessment"),
    meta: {
      authRequired: true,
      employeeUrl: true,
    }
  },
  {
    path: "/ess/manager/confirmation/confirm-assessment/:id",
    name: "EssCreateAssesment",
    component: () =>
      import("@/modules/Admin/EmployeeModule/EmployeeActions/Confirmation/createAssessment"),
    meta: {
      authRequired: true,
      employeeUrl: true,
    }
  },
  {
    path: "/ess/manager/promotions",
    name: "EssMSSPromotions",
    component: () =>
      import("@/modules/Ess/ManagerSelfService/employeeActions/promotions"),
    meta: {
      authRequired: true,
      employeeUrl: true,
    }
  },
  {
    path: "ess/compensation/review",
    name: "EssCompensationReview",
    component: () => import("@/modules/Ess/Compensation/Review"),
    meta: {
      authRequired: true,
      employeeUrl: true,
    }
  },
  {
    path: "ess/compensation/review/details/:id",
    name: "EssCompensationReviewDetailedReport",
    component: () => import("@/modules/Ess/Compensation/Review/DetailedReport"),
    meta: {
      authRequired: true,
      employeeUrl: true,
    }
  },
  {
    path: "/ess/manager/delegation",
    name: "EssMSSDelegation",
    component: () =>
      import("@/modules/Ess/ManagerSelfService/employeeActions/delegation"),
    meta: {
      authRequired: true,
      employeeUrl: true,
    }
  },
  {
    path: "/ess/manager/re-deployment",
    name: "EssMSSRedeployment",
    component: () =>
      import("@/modules/Ess/ManagerSelfService/employeeActions/redeployment"),
    meta: {
      authRequired: true,
      employeeUrl: true,
    }
  },
  {
    path: "/ess/overtime/:id",
    name: "EssOvertimeDetails",
    component: () =>
      import("@/modules/Admin/BenefitsModule/Overtime/overtimeDetails"),
    meta: {
      authRequired: true,
      employeeUrl: true,
    }
  },
  {
    path: "/ess/manager/performance",
    name: "EssMSSPerformance",
    component: () =>
      import("@/modules/Ess/ManagerSelfService/performance"),
    meta: {
      authRequired: true,
      employeeUrl: true,
    }
  },
  {
    path: "/ess/performance/agreements/direct-reports-agreement/:year",
    name: "DirectReportsDetails",
    component: () => import("@/modules/Ess/ManagerSelfService/performance/SharedAgreements/directReports"),
    meta: {
      authRequired: true,
      employeeUrl: true
    }
  },
  {
    path: "/ess/performance/agreements/shared/new",
    name: "CreateSharedAgreement",
    component: () => import("@/modules/Ess/ManagerSelfService/performance/SharedAgreements/NewAgreement"),
    meta: {
      authRequired: true,
      employeeUrl: true
    }
  },
  {
    path: "/ess/performance/agreements/adopt-employee-agreement/:id/:user",
    name: "AdoptSharedAgreement",
    component: () => import("@/modules/Ess/ManagerSelfService/performance/SharedAgreements/adoptAgreement"),
    meta: {
      authRequired: true,
      employeeUrl: true
    }
  },
  {
    path: "/ess/performance/agreements/view-draft-agreement/:agreement/:year",
    name: "draftAgreementDetails",
    component: () => import("@/modules/Ess/ManagerSelfService/performance/SharedAgreements/draftAgreement"),
    meta: {
      authRequired: true,
      employeeUrl: true
    }
  },
  {
    path: "/ess/performance/agreements/employee-agreement/:id/:year",
    name: "EssPerformanceEmployeeDetails",
    component: () => import("@/modules/Ess/ManagerSelfService/performance/SharedAgreements/employeeAgreement"),
    meta: {
      authRequired: true,
      employeeUrl: true
    }
  },
  {
    path: "/ess/performance/agreements/view-shared-agreement/:id/:agreement/:type",
    name: "SharedAgreementDetails",
    component: () => import("@/modules/Ess/ManagerSelfService/performance/SharedAgreements/viewAgreement"),
    meta: {
      authRequired: true,
      employeeUrl: true
    }
  },
]
