export default [
    {
        path: "/ess/documents",
        name: "EssOrganisationCompanyDocuments",
        component: () => import("@/modules/Ess/Documents/index"),
        meta: {
            authRequired: true,
            employeeUrl: true
        }
    },
    {
        path: "/ess/documents/employee",
        name: "EssEmployeeDocs",
        component: () => import("@/modules/Ess/Documents/employeeDocs/index"),
        meta: {
            authRequired: true,
            adminRequire: true,
        }
    },
    {
        path: "ess/documents/employee/view-folder/:id",
        name: "EssViewEmployeeFolder",
        component: () => import("@/modules/Ess/Documents/employeeDocs/documents/viewFolder"),
        meta: {
            authRequired: true,
            adminRequire: true,
        }
    },
    {
        path: "ess/documents/employee/recycleBin",
        name: "EssEmployeesRecycleBin",
        component: () => import("@/modules/Ess/Documents/employeeDocs/documents/recycleBin"),
        meta: {
            authRequired: true,
            adminRequire: true,
        }
    },
]