export default [
    {
        path: "/ess/performance/agreements",
        name: "EssAgrreements",
        component: () => import("@/modules/Ess/Performance/Agreements"),
        meta: {
            authRequired: true,
            employeeUrl: true
        }
    },

    {
        path: "/ess/performance/OrganisationalGoal/view/:id",
        name: "OrganisationlgoalView",
        component: () => import("@/modules/Ess/Performance/Agreements/OrgGoals/viewGoals"),
        meta: {
            authRequired: true,
            employeeUrl: true
        }
    },

    {
        path: "/ess/performance/agreements/new",
        name: "CreateAgreement",
        component: () => import("@/modules/Ess/Performance/Agreements/MyAgreements/NewAgreement"),
        meta: {
            authRequired: true,
            employeeUrl: true
        }
    },
    {
        path: "/ess/performance/agreements/view-agreement/:id/:cycle/:year",
        name: "AgreementDetails",
        component: () => import("@/modules/Ess/Performance/Agreements/MyAgreements/viewAgreement"),
        meta: {
            authRequired: true,
            employeeUrl: true
        }
    },
    {
        path: "/ess/performance/appraisals",
        name: "EssAppraisals",
        component: () => import("@/modules/Ess/Performance/Appraisals"),
        meta: {
            authRequired: true,
            employeeUrl: true
        }
    },
    {
        path: "/ess/performance/appraisals/my-appraisals/:year/:user/:cycle/:goalId",
        name: "EssMyAppraisals",
        component: () => import("@/modules/Ess/Performance/Appraisals/MyAppraisals"),
        meta: {
            authRequired: true,
            employeeUrl: true
        }
    },
    {
        path: "/ess/performance/appraisals/my-appraisal/:id/:type/:active",
        name: "EssMyAppraisal",
        component: () => import("@/modules/Ess/Performance/Appraisals/MyAppraisals"),
        meta: {
            authRequired: true,
            employeeUrl: true
        }
    },
    {
        path: "/ess/performance/agreements/shared-agreement/:id/:createdBy/:cycle",
        name: "viewAgreementDetails",
        component: () => import("@/modules/Ess/Performance/Agreements/MyAgreements/viewSharedAgreement"),
        meta: {
            authRequired: true,
            employeeUrl: true
        }
    },
    {
        path: "/ess/performance/appraisals/direct-reports/:id/:year",
        name: "EssAprraisalsDirectReports",
        component: () => import("@/modules/Ess/Performance/Appraisals/DirectReports"),
        meta: {
            authRequired: true,
            employeeUrl: true
        }
    },
    {
        path: "/ess/performance/appraisals/direct-reports/employee/:year/:userId/:cycle/:goalId",
        name: "EssPerformanceEmployeeAppraisals",
        component: () => import("@/modules/Ess/Performance/Appraisals/EmployeeAppraisal"),
        meta: {
            authRequired: true,
            employeeUrl: true
        }
    },
    {
        path: "/ess/performance/appraisals/scorecard/:id/:year",
        name: "EssPerformanceAppraisalScorecard",
        component: () => import("@/modules/Ess/Performance/Appraisals/AppraisalScorecard"),
        meta: {
            authRequired: true,
            employeeUrl: true
        }
    },
    {
        path: "/ess/performance/appraisals/status-reports/:id/:year",
        name: "EssEmployeeStatusReports",
        component: () => import("@/modules/Ess/Performance/Appraisals/EmployeeStatusReport"),
        meta: {
            authRequired: true,
            employeeUrl: true
        }
    },
    {
        path: "/ess/performance/moderation",
        name: "EssPerformanceModeration",
        component: () => import("@/modules/Ess/Performance/Moderation"),
        meta: {
            authRequired: true,
            employeeUrl: true
        }
    },
    {
        path: "/ess/performance/moderation/organisational-moderation/:year/:goalId",
        name: "ESSOrganisationModeration",
        component: () => import("@/modules/Ess/Performance/Moderation/OrganisationModeration"),
        meta: {
            authRequired: true,
            employeeUrl: true
        }
    },
    {
        path: "/ess/performance/moderation/ceo-moderation/:year/:goalId",
        name: "EssCeoModerationDashboard",
        component: () => import("@/modules/Ess/Performance/Moderation/CeoModeration"),
        meta: {
            authRequired: true,
            employeeUrl: true
        }
    },
    {
        path: "/ess/performance/performance-improvement",
        name: "EssImprovement",
        component: () => import("@/modules/Ess/Performance/Improvement"),
        meta: {
            authRequired: true,
            employeeUrl: true
        }
    },
    {
        path: "/ess/performance/performance-improvement/pip/:id",
        name: "ESSPerformanceImprovmentDetails",
        component: () => import("@/modules/Ess/Performance/Improvement/PerformanceImprovementDetails"),
        meta: {
            authRequired: true,
            employeeUrl: true
        }
    },
    {
        path: "/ess/performance/performance-improvement/improvementplan/:id/:tmpId",
        name: "ESSPerformanceImprovementPlan",
        component: () => import("@/modules/Ess/Performance/Improvement/ImprovementPlan"),
        meta: {
            authRequired: true,
            employeeUrl: true
        }
    },
    {
        path: "/ess/performance/performance-improvement/ppr/:id",
        name: "ESSPerformanceReviewDetails",
        component: () => import("@/modules/Ess/Performance/Improvement/PerformanceReviewDetails"),
        meta: {
            authRequired: true,
            employeeUrl: true
        }
    },
    {
        path: "/ess/performance/performance-improvement/pcs/:id/:templateId",
        name: "ESSCloseOutDetails",
        component: () => import("@/modules/Ess/Performance/Improvement/performanceCloseout"),
        meta: {
            authRequired: true,
            employeeUrl: true
        }
    },
    {
        path: "/ess/performance/performance-improvement/ppr/details/:id/:tmpId",
        name: "ESSPerformanceImprovementDetails",
        component: () => import("@/modules/Ess/Performance/Improvement/ReviewSession/ReviewSessionDetails"),
        meta: {
            authRequired: true,
            employeeUrl: true
        }
    },
    {
        path: "/ess/performance/performance-improvement/pcs/:id",
        name: "ESSPerformanceCloseoutDetails",
        component: () => import("@/modules/Ess/Performance/Improvement/PerformanceCloseoutDetails"),
        meta: {
            authRequired: true,
            employeeUrl: true
        }
    },
]
