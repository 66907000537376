/* eslint-disable import/prefer-default-export */
import { AxiosHumanar,  AxiosORGS, OrgId } from "./orgRepository";

export const overtimeApi = {
  $_addOverTimeRequest(payload) {
    const url = "/ess/overtime/pay/requests";
    return AxiosHumanar.post(url, payload);
  },
  $_approveOverTimePlannedRequest(payload) {
    const url = "/ess/overtime/planned-works/employees-approval";
    return AxiosHumanar.post(url, payload);
  },
  $_approveOverTimeEmployeeDetailsRequest(payload) {
    const url = "/ess/overtime/planned-works/approval";
    return AxiosHumanar.post(url, payload);
  },
  $_adminPlannedWorkRequests(query) {
    const url = `/overtime/all-planned-work-request/${OrgId}?${query}`;
    return AxiosHumanar.get(url);
  },
  $_adminOvertimePayRequests(query = '') {
    const url = `/overtime/all-pay-request/${OrgId}?${query}`;
    return AxiosHumanar.get(url);
  },
  $_adminPlannedWorkStats() {
    const url = `/overtime/planned-work-request/statistics/${OrgId}`;
    return AxiosHumanar.get(url);
  },
  $_adminOvertimePayStats() {
    const url = `/overtime/pay-request/statistics/${OrgId}`;
    return AxiosHumanar.get(url);
  },

  $_adminRequestType({ month, year }) {
    const url = `/overtime/pay-requests-type/${OrgId}/${month}/${year}`;
    return AxiosHumanar.get(url);
  },
  $_adminRequestEmployee({ month, year, paySettingId, status }) {
    const url = `/overtime/pay-requests/${OrgId}/${month}/${year}?payApprovalStatus=${status}&paySettingId=${paySettingId}`;
    return AxiosHumanar.get(url);
  },
  $_deleteOvertimeDraft(id) {
    const url = `/overtime/request/delete/${id}`;
    return AxiosHumanar.delete(url);
  },
  $_approveOverTimePayRequest(payload) {
    const url = "/ess/overtime/pay-requests/employees-approval";
    return AxiosHumanar.post(url, payload);
  },
  $_approveOverTimeDetailedPayRequest(payload) {
    const url = "/ess/overtime/pay-requests/approval";
    return AxiosHumanar.post(url, payload);
  },
  $_addOverPreApprovalRequest(payload) {
    const url = "/ess/overtime/preapproval/requests";
    return AxiosHumanar.post(url, payload);
  },
  $_updatePreApprovalRequest(payload, { requestId }) {
    const url = `/overtime/requests/${requestId}`;
    return AxiosHumanar.patch(url, payload);
  },
  $_getPreApprovalRequest({ managerUserId, year }) {
    const url = `/ess/overtime/manager/planned-works/${managerUserId}/${year}`;
    return AxiosHumanar.get(url);
  },
  $_getPayRequest({ managerUserId, year }) {
    const url = `/ess/overtime/manager/pay-requests/${managerUserId}/${year}`;
    return AxiosHumanar.get(url);
  },
  $_getPlannedWorkRequest({ managerUserId, month, query, year }) {
    const url = `ess/overtime/manager/planned-works/${managerUserId}/${month}/${year}?preApprovalStatus=${query}`;
    return AxiosHumanar.get(url);
  },
  $_getEmployeePayRequest({ managerUserId, month, query, year }) {
    const url = `ess/overtime/manager/pay-requests/${managerUserId}/${month}/${year}?payApprovalStatus=${query}`;
    return AxiosHumanar.get(url);
  },
  $_getPreApprovalRequestsByBatchCode({ employeeId, batchCode, query }) {
    const url = `/overtime/preapproval/requests/${employeeId}/${batchCode}?preApprovalStatus=${query}`;
    return AxiosHumanar.get(url);
  },
  $_getOvertimePayRequests({ userId, year }) {
    const url = `ess/overtime/pay-requests/${userId}/${year}`;
    return AxiosHumanar.get(url);
  },
  $_getOvertimePayRequestsByBatchCode({ employeeId, batchCode, query }) {
    const url = `/overtime/pay/requests/${employeeId}/${batchCode}?payApprovalStatus=${query}`;
    return AxiosHumanar.get(url);
  },
  $_getOvertimePlannedWork({ employeeId, year }) {
    const url = `/ess/overtime/planned-works/${employeeId}/${year}`;
    return AxiosHumanar.get(url);
  },
  $_getOvertimePayItem({ employeeId }) {
    const url = `/org/employees/${employeeId}/employee-paysettings?payItemSlug=overtimeAllowance`;
    return AxiosORGS.get(url);
  },
  $_getPayRequestPlannedWork({ employeeId }) {
    const url = `overtime/planned-works-for-pay/${employeeId}`;
    return AxiosHumanar.get(url);
  },
  $_getOvertimeRequestAnalytics({ managerUserId }) {
    const url = `/ess/overtime/manager/planned-works/${managerUserId}/summary`;
    return AxiosHumanar.get(url);
  },
  $_getOvertimePayAnalytics({ managerUserId }) {
    const url = `/ess/overtime/manager/pay-requests/${managerUserId}/summary`;
    return AxiosHumanar.get(url);
  },
  $_getPlannedWorks(userId, query) {
    const url = `overtime/request/planned-works/${userId}?${query}`;
    return AxiosHumanar.get(url);
  },
  $_getPayRequestWork(userId, query) {
    const url = `overtime/request/pay-request-works/${userId}?${query}`;
    return AxiosHumanar.get(url);
  },
  $_getOvertimePayRequestWork(userId) {
    const url = `get-overtime/planned-works-for-pay/${userId}`;
    return AxiosHumanar.get(url);
  },
  $_createPayRequest(payload) {
    const url = `/ess/overtime/pay/requests`;
    return AxiosHumanar.post(url, payload);
  }
};
