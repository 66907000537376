<template>
  <div class="loading-screen ">
    <div class="loading-animation">
      <Icon
        class-name="text-flame logo"
        v-if="loaderImage"
        icon-name="cloudenly_logo"
        :size="size"
      />
      <div v-if="loadingBar" class="loading-bar" :style="loadingBarSize" />
    </div>
  </div>
</template>

<script>
import Icon from "./Icon";

export default {
  components: { Icon },
  props: {
    size: {
      type: String,
      default: "xms"
    },
    loaderImage: {
      type: Boolean,
      default: true
    },
    loadingBar: {
      type: Boolean,
      default: true
    },
    loadingBarSize: {
      type: String,
      default: 'width: 130px'
    },
  }
};
</script>

<style scoped>
.loading-animation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.loading-screen {
  /* position: fixed; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.logo {
  width: 100px;
  animation: bounce 1.5s infinite ease;
}

@keyframes bounce {
  0% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  25% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  60% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
}

.loading-bar {
  height: 2px;
  background: #cfcfcf;
  margin-top: 22px;
  position: relative;
  overflow: hidden;
}

.loading-bar::before {
  content: "";
  width: 68px;
  height: 2px;
  background: #fe9e60;
  position: absolute;
  left: -34px;
  animation: bluebar 1.5s infinite ease;
}

@keyframes bluebar {
  50% {
    left: 96px;
  }
}
</style>
